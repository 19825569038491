function ReviewsService ( $http, CityConfigService ) {
  "ngInject";

  var getReviews = function ( page ) {
    var reviewsShopToken = CityConfigService.options.config.reviews_shop_token;

    if( reviewsShopToken != '' ) {
      if( isNaN( page ) ) {
        page = 1;
      }

      return $http({
        url: 'https://xn----btbkclpc6dce3bm1c.xn--p1ai/api/shops/' + reviewsShopToken + '/feedbacks',
        method: 'GET',
        params: {
          page: page,
        },
        cache: false
      })
    }

    return [];
  }

  return {
    getReviews: getReviews
  }
}

module.exports = ReviewsService;