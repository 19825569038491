function AdditionalGoodsController ( AdditionalGoodsService, CityConfigService )
{
    "ngInject";

    var vm = this;
    vm.additionalGoods = AdditionalGoodsService;
    vm.cityConfig = CityConfigService;

    vm.$onInit = function ()
    {
        AdditionalGoodsService.items = [];
        AdditionalGoodsService.getCategoriesList();
    }
}


module.exports = {
    templateUrl: '/partials/components/AdditionalGoodsTemplate.tpl?' + __webpack_hash__,
    controller: AdditionalGoodsController,
    controllerAs: 'additionalGoodsCtrl'
}