function PriceFilterController ( $routeParams, PriceFilterService, CityConfigService )
{
  "ngInject";

  var vm = this;

  vm.filterValue = [];
  vm.options = {
      min: 0,
      max: 0,
      step: 10,
      value: [0, 0],
      range: true,
      categoryId: null,
      citySlug: null
  };
  vm.initialized = false;

  vm.$onInit = function () {
    vm.options.categoryId = $routeParams.categoryId;
    vm.options.citySlug = $routeParams.slug;

    PriceFilterService.getCategoryRange( vm.options.categoryId ).then( function ( response ) {
      var data = response.data.data;

      vm.options.max = parseInt( data.max );
      vm.options.min = parseInt( data.min );
      vm.options.value = [ vm.options.min, vm.options.max ];

      if ( typeof( $routeParams.minPrice ) != "undefined" && typeof( $routeParams.maxPrice ) != "undefined" ) {
        vm.options.value = [ parseInt( $routeParams.minPrice ), parseInt( $routeParams.maxPrice ) ];
      }

      vm.initialized = true;
    });
  }
}

module.exports = {
  templateUrl: '/partials/components/PriceFilterTemplate.tpl?' + __webpack_hash__,
  controller: PriceFilterController,
  controllerAs: 'priceFilterCtrl'
}