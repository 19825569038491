var angular = require("angular");

angular.module( "flowersApp" )
    .controller( "AppController", require( "./AppController" ) )
    .controller( "IndexController", require( "./IndexController" ) )
    .controller( "CatalogItemListController", require( "./CatalogItemListController" ) )
    .controller( "CityListWindowController", require( "./CityListWindowController" ) )
    .controller( "ContentController", require( "./ContentController" ) )
    .controller( "NotFoundController", require( "./NotFoundController" ) )
    //.controller( "BannersController", require( "./BannersController" ) )
    .controller( "CategoriesController", require( "./CategoriesController" ) )
    .controller( "CatalogItemController", require( "./CatalogItemController" ) )
    .controller( "PurchaseController", require( "./PurchaseController" ) )
    .controller( "SuccessPaymentController", require( "./SuccessPaymentController" ) )
    .controller( "FastOrderController", require( "./FastOrderController" ) )
    .controller( "HelpersController", require( "./HelpersController" ) )
    .controller( "BouquetPhotoController", require( "./BouquetPhotoController" ) )
    .controller( "MainMenuController", require( "./MainMenuController" ) )
    //.controller( "PriceFilterController", require( "./PriceFilterController" ) )
    .controller( "SearchController", require( "./SearchController" ) )
    .controller( "SearchFormController", require( "./SearchFormController" ) )
    //.controller( "CartWidgetController", require( "./CartWidgetController" ) )
    .controller( "CartPageController", require( "./CartPageController" ) )
    .controller( "CartController", require( "./CartController" ) )
    .controller( "TransactionController", require( "./TransactionController" ) )
    .controller( "ReviewsController", require( "./ReviewsController" ) )
    .controller( "SeoMenuController", require( "./SeoMenuController" ) )
    .controller( "CookieNotificationController", require( "./CookieNotificationController" ) );