function EcommerceService ( $q, $timeout, $window, CityConfigService ) {
  "ngInject";

  /**
   * Метод для формирования запроса о транзакции в Google Analytics и Яндекс.Метрику
   *
   * @param transactionObject
   */
  var makeEcommerceTransaction = function( transactionObject, delay ) {
    var defer = $q.defer();

    $window.ga( 'ecommerce:addTransaction', {
      'id': transactionObject.orderNumber,
      'affiliation': '"' + CityConfigService.options.config.shop_name + '", г. ' + CityConfigService.options.config.city,
      'revenue': transactionObject.orderSum.withDiscount,
      'shipping': transactionObject.orderSum.deliveryPrice,
      'currency': 'RUB'
    });

    var yandexProductsList = [];

    transactionObject.orderItems.forEach( function ( item, index, sourceItems ) {

      yandexProductsList.push({
        "id": item.id,
        "name": item.name,
        "category": item.category,
        "price": item.price,
        "quantity": item.quantity
      });

      $window.ga('ecommerce:addItem', {
        'id': transactionObject.orderNumber,
        'name': item.name,
        'sku': item.id,
        'category': item.category,
        'price': item.price,
        'quantity': item.quantity,
        'currency': 'RUB'
      });
    });

    $window.ga( 'ecommerce:send' );
    $window.ga( 'ecommerce:clear' );

    if( typeof($window.dataLayer) != "undefined" ) {
      $window.dataLayer.push({
        "ecommerce": {
          "purchase": {
            "actionField": {
              "id": transactionObject.orderNumber
            },
            "products": yandexProductsList
          }
        }
      });
    }

    if( !isNaN( delay ) || parseInt( delay ) > 0 ) {
      $timeout( function () {
        defer.resolve()
      }, delay );
    }

    return defer.promise;
  }

  return {
    makeEcommerceTransaction: makeEcommerceTransaction
  }

}

module.exports = EcommerceService;