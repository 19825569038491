function CartWidgetController ( $timeout, $location, $rootScope, CartService, CityConfigService )
{
    "ngInject";

    var vm = this;
    vm.cartService = CartService;
    vm.isAnimating = false;

    vm.animationTimeout = null;

    vm.getCartUrl = function () {
        return "/" + CityConfigService.options.city.slug + "/cart";
    }

    $rootScope.$on( 'cart-changed', function () {
        vm.isAnimating = true;

        if( vm.animationTimeout !== null ) {
            $timeout.cancel( vm.animationTimeout );
        }

        vm.animationTimeout = $timeout( function () {
            vm.isAnimating = false;
        }, 600 );
    });
}

module.exports =  {
    templateUrl: '/partials/components/CartWidgetTemplate.tpl?' + __webpack_hash__,
    controller: CartWidgetController,
    controllerAs: 'cartWidgetCtrl',
};