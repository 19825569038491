function PromoCodeService ($q, $cookies, $http, CityConfigService) {
  'ngInject'

  var get = function () {
    return $cookies.get('promoCode')
  }

  var set = function (code) {
    return $cookies.put('promoCode', code)
  }

  var remove = function () {
    return $cookies.remove('promoCode')
  }

  var isExists = function () {
    return typeof ($cookies.get('promoCode')) !== 'undefined'
  }

  var check = function (code) {
    return $http({
      url: CityConfigService.options.city.base_url + '/api/handler.php',
      method: 'GET',
      params: {
        request: 'checkPromo',
        code: code,
      },
      cache: false,
    })
  }

  return {
    get: get,
    set: set,
    remove: remove,
    isExists: isExists,
    check: check,
  }
}

module.exports = PromoCodeService