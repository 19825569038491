function SearchService( CityConfigService, $http )
{
    "ngInject";

    var data = {
        isLoading: false,
        items: [],
        currentPage: 0,
        isEndPage: false
    };

    var lazyLoadItems = function ( string )
    {
        var self = this;

        if ( self.data.isLoading || self.data.isEndPage ) {
            return;
        }

        var requestParams = {
            'request': 'search',
            'string': string,
            'limit': 12,
            'p': self.data.currentPage
        }

        self.data.isLoading = true;

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: 'GET',
            params: requestParams
        }).then( function ( response )
        {
            var recievedItems = response.data.data.items;
            self.data.isEndPage = response.data.data.isEndPage;

            if( !self.data.isEndPage ) {
                self.data.currentPage++;
            }

            if( typeof( recievedItems ) !== 'undefined' && recievedItems.length > 0 )
            {
                Array.prototype.push.apply( self.data.items, recievedItems );
            } else {
                self.data.isEndPage = true;
                self.data.items = [];
            }

            self.data.isLoading = false;
        }).catch( angular.noop );
    }

    var init = function()
    {
        this.data = {
            isLoading: false,
            items: [],
            currentPage: 0,
            isEndPage: false
        };
    }

    return {
        data: data,
        init: init,
        lazyLoadItems: lazyLoadItems
    }
}

module.exports = SearchService;