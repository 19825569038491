function KaomojiController () {
    var vm = this;
    vm.kaomojiItem = '';
    vm.kaomojiList = [
        "(ノ_<。)",
        "(-_-)",
        "(´-ω-`)",
        ".･ﾟﾟ･(／ω＼)･ﾟﾟ･.",
        "(μ_μ)",
        "(ﾉД`)",
        "(-ω-、)",
        "。゜゜(´Ｏ`) ゜゜。",
        "o(TヘTo)",
        "( ; ω ; )",
        "(｡╯︵╰｡)",
        "｡･ﾟﾟ*(>д<)*ﾟﾟ･｡",
        "( ﾟ，_ゝ｀)",
        "(个_个)",
        "(╯︵╰,)",
        "｡･ﾟ(ﾟ><ﾟ)ﾟ･｡",
        "( ╥ω╥ )",
        "(╯_╰)",
        "(╥_╥)",
        ".｡･ﾟﾟ･(＞_＜)･ﾟﾟ･｡.",
        "(／ˍ・、)",
        "(ノ_<、)",
        "(╥﹏╥)",
        "｡ﾟ(｡ﾉωヽ｡)ﾟ｡",
        "(つω`｡)",
        "(｡T ω T｡)",
        "(ﾉω･､)",
        "･ﾟ･(｡>ω<｡)･ﾟ･",
        "(T_T)",
        "(>_<)",
        "(っ˘̩╭╮˘̩)っ",
        "｡ﾟ･ (>﹏<) ･ﾟ｡",
        "o(〒﹏〒)o",
        "(｡•́︿•̀｡)",
        "(ಥ﹏ಥ)"
    ];

    vm.getRandomKaomoji = function () {
        vm.kaomojiItem = vm.kaomojiList[ Math.floor( ( Math.random() * vm.kaomojiList.length ) ) ];
    }

    vm.getRandomKaomoji();
}

function template (){
    return `<span>{{ ::kaomojiCtrl.kaomojiItem }}</span>`;
}

module.exports = {
    template: template(),
    controller: KaomojiController,
    controllerAs: 'kaomojiCtrl',
}