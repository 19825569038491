function CityListService (
  $location, $uibModal, AppService, AppConfigService, CatalogItemListService,
  BannersService) {
  'ngInject'

  let windowInstance = undefined

  var closeCityListWindow = function () {
    windowInstance.close()
  }

  var selectCity = function (selectedCity) {
    CatalogItemListService.clearCatalogData().then(function (result) {
      return BannersService.clearBannersList()
    }).then(function (result) {
      AppService.initCity(selectedCity)
      $location.url('/' + selectedCity.slug)
      closeCityListWindow()
    })
  }

  var openCityListWindow = function () {
    windowInstance = $uibModal.open({
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      controller: 'CityListWindowController as cityListCtrl',
      templateUrl: '/partials/includes/city_list_window.tpl?' + __webpack_hash__,
    })
  }

  return {
    openCityListWindow: openCityListWindow,
    closeCityListWindow: closeCityListWindow,
    selectCity: selectCity,
  }
}

module.exports = CityListService