/**
 * Сервис для работы с геолокацией
 * @returns {{isGeolocationAvailable: (function(): boolean)}}
 * @constructor
 */
function GeoLocationService ($q, $http) {
  'ngInject'

  let navigatorGeolocation = navigator.geolocation

  /**
   * Метод для проверки доступности геолокации в браузере
   * @returns {boolean}
   */
  let isGeolocationAvailable = function () {
    return (!navigatorGeolocation ? false : true)
  }

  /**
   * Метод для определения города оп геолокации
   * @returns {boolean|void}
   */
  let detectCity = function () {
    if(!isGeolocationAvailable()) {
      return false
    }

    return getCurrentCoordinates().then(function(result) {
      return result.coords
    }).then(function(result) {
      return reverseGeocoding(result)
    }).then( function (result) {
      return result
    })
  }

  /**
   *  Метод для получения текущих координат
   * @returns {Promise<any>}
   */
  let getCurrentCoordinates = function () {
    return $q(function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(resolve, reject)
    })
  }

  /**
   * Метод для получения имени города
   * @param coords
   * @returns {*}
   */
  let reverseGeocoding = function (coords) {
    return $http.get('/api/geocoding/city-name', {
      params: {
        lat: coords.latitude,
        lon: coords.longitude,
      },
      cache: true
    })
  }

  return {
    detectCity: detectCity,
    isGeolocationAvailable: isGeolocationAvailable
  }
}

module.exports = GeoLocationService