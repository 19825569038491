function template () {
  return `
    <div class="dropdown" ng-class="{open: catalogFilterFieldPriceCtrl.open}">
        <button class="btn btn-link btn-block dropdown-toggle" type="button" ng-click="catalogFilterFieldPriceCtrl.toggleDropdown()">
            <span class="pull-left">
                Цена, &#x20bd;
                <span class="badge" ng-if="catalogFilterFieldPriceCtrl.isSelected() === true">
                    1
                </span>  
            </span>
            <span>
                &nbsp;                
            </span>            
            <span>
                <span class="caret"></span>
            </span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
            <li>
                <div class="row">
                    <div class="col-xs-5 col-xs-offset-1">
                        <input type="number" name="fields_filter[price][0]" ng-model="catalogFilterFieldPriceCtrl.field.selected.min" class="form-control input-sm" placeholder="{{catalogFilterFieldPriceCtrl.field.options.min}}" />
                    </div>
                    <div class="col-xs-5">
                        <input type="number" name="fields_filter[price][1]" ng-model="catalogFilterFieldPriceCtrl.field.selected.max" class="form-control input-sm" placeholder="{{catalogFilterFieldPriceCtrl.field.options.max}}" />
                    </div>
                </div>
            </li>
        </ul>        
    </div>
  `
}

function CatalogFilterFieldPriceController ($scope) {
  'ngInject'

  let vm = this
  vm.open = false

  vm.toggleDropdown = function () {
    if (vm.open) {
      vm.open = false
    } else {
      vm.addEventListener()
      vm.open = true
    }
  }

  vm.addEventListener = function () {
    document.addEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.removeEventListener = function () {
    document.removeEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.closeOnClickOutsideEvent = function (event) {
    if (!event.target.closest(
      'catalog-filter-field-price')) {
      vm.removeEventListener()
      $scope.$apply(function () {
        vm.open = false
      })
    }
  }

  vm.isSelected = function () {
    return (vm.field.selected.min != vm.field.options.min || vm.field.selected.max != vm.field.options.max)
  }

  vm.$onInit = function () {
  }
}

module.exports = {
  template: template(),
  controller: CatalogFilterFieldPriceController,
  controllerAs: 'catalogFilterFieldPriceCtrl',
  bindings: {
    field: '=',
  },
}
