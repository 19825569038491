function ContentController ( $routeParams, ContentService, CityConfigService ) {
    "ngInject";

    var vm = this;
    vm.pageContent = null;
    vm.cityConfig = CityConfigService;

    vm.yandexMapBaloonConfig = {
        geometry: {
            type: 'Point',
            coordinates: CityConfigService.options.config.yandex_map_coords
        },
        properties: {
            balloonContentHeader: CityConfigService.options.config.header_address
        }
    };

    vm.loadContent = function () {
        var pageUrlName = $routeParams.name;

        ContentService.getContent( pageUrlName )
        .then( function ( response ) {
            vm.pageContent = response;
        });
    }

    vm.init = function () {
        vm.loadContent()
    }

    vm.init();
}

module.exports = ContentController;