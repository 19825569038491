function CartService ( $http, $rootScope, $window, CityConfigService ) {
    "ngInject";

    var data = {
        itemsQuantity: 0,
        items: []
    };

    var loadFromLocalStorage = function () {
        var savedCart = null;
        if( savedCart = localStorage.getItem( CityConfigService.options.city.slug + "-cart" ) )
        {
            var storedItems = angular.fromJson( savedCart );
            if( typeof( storedItems ) === 'object' )
            {
                data.items = angular.fromJson( savedCart );
            }
        } else {
            data.items = [];
        }

        recalcItemsQuantity();
    }

    var saveToLocalStorage = function () {
        localStorage.setItem( CityConfigService.options.city.slug + "-cart", angular.toJson( data.items ) );
    }

    var recalcItemsQuantity = function () {
        data.itemsQuantity = 0;
        data.items.forEach( function( item, index, array ){
            data.itemsQuantity += item.quantity;
        });
    }

    var getCartItem = function ( item ) {
        return data.items.find( function ( element ) {
            return element.id === item.id;
        });
    }

    var isItemInCart = function ( item ) {
        var result = null;
        result = data.items.find( function ( element ) {
            return element.id === item.id;
        });

        return typeof( result ) !== 'undefined';
    }

    var appendToCart = function ( item, quantity ) {
        var cartItem = null;

        if( cartItem = getCartItem( item ) ) {
            if( quantity === 0 ) {
                removeFromCart( cartItem );
            } else {
                cartItem.quantity = ( isNaN( quantity ) ? cartItem.quantity++ : quantity );
                $rootScope.$emit( 'cart-changed' );
            }
        } else {
            item.quantity = ( quantity > 1 ) ? quantity : 1;
            data.items.push( item );

            $rootScope.$emit( 'cart-changed' );
        }
    }

    var removeFromCart = function ( targetItem ) {
        data.items = data.items.filter(function ( item ) {
            return item.id != targetItem.id;
        });

        $rootScope.$emit( 'cart-changed' );
    }

    var clearCart = function () {
        data.items.splice( 0, data.items.length );
        $rootScope.$emit( 'cart-changed' );
    }

    var setEventWatchers = function () {

        $rootScope.$on( 'after-city-change', function () {
            loadFromLocalStorage();
        });

        $rootScope.$on( 'before-city-change', function () {
            saveToLocalStorage();
        });

        $rootScope.$on( 'cart-changed', function () {
            saveToLocalStorage();
            recalcItemsQuantity();
        });

        $rootScope.$on( 'only-save-cart', function () {
            saveToLocalStorage();
        });

    }

    setEventWatchers();

    return {
        data: data,
        loadFromLocalStorage: loadFromLocalStorage,
        saveToLocalStorage: saveToLocalStorage,
        appendToCart: appendToCart,
        removeFromCart: removeFromCart,
        clearCart: clearCart,
        isItemInCart: isItemInCart,
    }
}

module.exports = CartService;