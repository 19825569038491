function ScrollToFirstInvalidInputDirective() {
  return {
    restrict: 'A',
    scope: false,
    controller: function ( $window, $element ) {
      "ngInject";
      var vm = this;

      vm.$onInit = function () {
        $element.on( 'submit', function () {
            var firstInvalidInput = $window.document.querySelector('form .ng-invalid');
            if ( firstInvalidInput ) {
              firstInvalidInput.focus();
            }
        });
      }
    }
  }
}

module.exports = ScrollToFirstInvalidInputDirective;