function template () {
  return `
    <div class="col-xs-12 col-sm-6 col-md-6" ng-repeat="paymentMethod in $ctrl.methodsList | filter: $ctrl.filterPayments track by $index">
      <div class="radio">
        <label>
          <input type="radio" ng-model="$ctrl.selectedMethod" ng-value="paymentMethod['id']" ng-click="$ctrl.selectedMethodParentId = paymentMethod.parentId">
          {{ paymentMethod['title'] }}
        </label>
      </div>
    </div>
  `
}

function PaymentMethodsController (AppConfigService) {
  'ngInject'

  let vm = this

  vm.filterPayments = function (element) {
    return !AppConfigService.options.excludedPayments.includes(element.alias)
  }
}

module.exports = {
  template: template(),
  controller: PaymentMethodsController,
  bindings: {
    methodsList: '<',
    selectedMethod: '=',
    selectedMethodParentId: '=',
  },
}