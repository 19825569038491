function RandomCategoryController( CityConfigService ) {
    "ngInject";
    var vm = this;
    vm.randomCategory = {};

    vm.getRandomCategory = function () {
        if( typeof( vm.randomCategory.link ) == 'undefined' ) {
            if ((CityConfigService.options.categories != undefined || CityConfigService.options.categories != null)) {
                vm.randomCategory = Object.assign( {}, CityConfigService.options.categories[Math.floor((Math.random() * CityConfigService.options.categories.length))] );
                vm.randomCategory.link = "/" + CityConfigService.options.city.slug + vm.randomCategory.link;
                vm.randomCategory.title = vm.randomCategory.name + " " + CityConfigService.options.city.name;
            } else {
                vm.randomCategory.name = "Акции";
                vm.randomCategory.link = "/" + CityConfigService.options.city.slug + '/catalog/actions';
                vm.randomCategory.title = "Акции " + CityConfigService.options.city.name;
            }
        }
    }

    vm.getRandomCategory();
}

function template() {
    return `<a ng-href="{{ randomCategoryCtrl.randomCategory.link }}" title="{{ randomCategoryCtrl.randomCategory.title }}">{{ randomCategoryCtrl.randomCategory.name }}</a>`;
}

module.exports = {
    template: template(),
    controller: RandomCategoryController,
    controllerAs: 'randomCategoryCtrl',
}