/*
     Контроллер для элемента каталога
*/

function CatalogItemListController ( $rootScope, CatalogItemListService ) {

    "ngInject";

    var vm = this;

    vm.catalog = CatalogItemListService;

    vm.onPriceFilterApply = function () {
        vm.initCatalogList();
    }

/*    vm.setWatchers = function () {
        $rootScope.$on( 'price-filter-apply', function () {
            vm.onPriceFilterApply();
        });
    }*/

    vm.lazyLoadItems = function () {
        vm.catalog.lazyLoadItems();
    }

    vm.initCatalogList = function ( catalogId, setMetaData ) {
        vm.catalog.initCatalogList( catalogId, setMetaData );
    };
}

module.exports = CatalogItemListController;