function MetaService ( $rootScope, $window, $location, $interpolate, AppConfigService, CityConfigService )
{
    'ngInject';
    /**
     * Метод для установки значений для мета-тегов
     *
     * @param tagData
     */
    var setMetaTags = function ( tagData ) {

        let seoMetaData = Object.assign({}, CityConfigService.options.config, tagData)

        if (typeof(tagData.title) != "undefined") {
            let documentTitle = interpolateData(AppConfigService.options.seo.titleTemplate, seoMetaData)
            documentTitle = interpolateData(documentTitle, seoMetaData)

            $window.document.getElementsByName( 'title' )[0].content = documentTitle;
            $window.document.querySelector( 'meta[property="og:title"]' ).setAttribute('content', documentTitle);
            $window.document.querySelector( 'meta[name="twitter:title"]' ).setAttribute('content', documentTitle);

            $rootScope.title = documentTitle;
        }

        let descriptionTemplate = (typeof tagData.descriptionTemplate !== 'undefined') ? tagData.descriptionTemplate : AppConfigService.options.seo.descriptionTemplate
        let documentDescription = interpolateData(descriptionTemplate, seoMetaData)

        $window.document.getElementsByName('description')[0].content = documentDescription;
        $window.document.querySelector('meta[property="og:description"]').setAttribute('content', documentDescription);
        $window.document.querySelector('meta[name="twitter:description"]').setAttribute('content', documentDescription);

        let keywordsTemplate = (typeof tagData.keywordsTemplate !== 'undefined') ? tagData.keywordsTemplate : AppConfigService.options.seo.keywordsTemplate
        let documentKeywords = interpolateData(keywordsTemplate, seoMetaData)

        $window.document.getElementsByName('keywords')[0].content = documentKeywords;


        if ( typeof( tagData.img ) != "undefined" ) {
            imgUrl = tagData.img;
            $window.document.querySelector('meta[property="og:image"]').setAttribute( 'content', imgUrl );
            $window.document.querySelector('meta[property="og:image:alt"]').setAttribute( 'content', $rootScope.title );

            $window.document.querySelector('meta[name="twitter:image:src"]').setAttribute( 'content', imgUrl );
        }

        $window.document.querySelector('meta[property="og:url"]').setAttribute('content', $location.absUrl());
        $window.document.querySelector('meta[name="twitter:url"]').setAttribute('content', $location.absUrl());

        var element = $window.document.getElementsByName('fragment')[0];

        if ( typeof( element ) != "undefined" ) {
            element.parentNode.removeChild(element);
        }
    };

    /**
     * Метод для интерполяции переданного шаблона
     *
     * @param template
     * @param data
     * @returns {*}
     */
    var interpolateData = function (template, data) {
        templateToInterpolate = $interpolate(template)
        return templateToInterpolate(data)
    }

    return {
        setMetaTags: setMetaTags
    }
}

module.exports = MetaService;