function CatalogFilterComponentService ($location, $http, CityConfigService) {
  'ngInject'

  let getFilterFields = function (catalogItemId) {
    let requestParams = {
      request: 'getCatalogFilter',
      categoryId: catalogItemId,
    }

    Object.assign(requestParams, $location.search())

    return $http({
      url: CityConfigService.options.city.base_url + "/api/handler.php",
      method: "GET",
      params: requestParams,
      cache: false
    })
  }

  return {
    getFilterFields: getFilterFields,
  }
}

module.exports = CatalogFilterComponentService