function SearchController ( $window, $routeParams, CityConfigService, SearchService, MetaService, StatusCodeService )
{
    "ngInject";

    var vm = this;

    vm.searchService = SearchService;
    vm.searchQuery = "";

    vm.setMetadata = function ()
    {
        var cityName = CityConfigService.options.city.name,
            pageName = 'Поиск по каталогу товаров';

        MetaService.setMetaTags({
            title: pageName,
            cityName: cityName
        });
    }

    vm.lazyLoadItems = function ()
    {
        vm.searchService.lazyLoadItems( vm.searchQuery );
    }

    vm.init = function ()
    {
        vm.searchService.init();
        vm.setMetadata();
        vm.searchQuery = $routeParams.q;
        vm.lazyLoadItems();
    }

    vm.init();
}

module.exports = SearchController;