function SearchFormController( $scope, $window, $location, $timeout, SearchFormService, CityConfigService )
{
    "ngInject";

    var vm = this;

    vm.data = SearchFormService.data;
    vm.searchQuery = "";
    vm.cityConfig = CityConfigService.options.city;
    vm.timer = false;
    vm.hideAutocompleteList = false;
    vm.autocompleteWatcher = false;

    vm.setWatchers = function ()
    {
        $scope.$watch(
            function()
            {
                return vm.searchQuery;
            },
            function ( newValue, oldValue )
            {
                if( ( typeof ( newValue ) != "undefined" ) && newValue.length > 2 )
                {
                    vm.setTimeout( newValue );
                }
            }
        );
    }

    vm.setTimeout = function ( searchString )
    {
        vm.data.items = [];
        $timeout.cancel( vm.timer );
        vm.timer = $timeout( function ()
        {
            vm.autocompleteSearch( searchString );
            vm.timer = false;
        }, 400 );
    }

    vm.autocompleteSearch = function ( searchString )
    {
        SearchFormService.search( searchString );
    }

    vm.goToSearchPage = function  ()
    {
        $location.url( '/' + vm.cityConfig.slug + '/search?q=' + vm.searchQuery );
    }

    vm.onItemSelected = function ( $event )
    {
        vm.hideAutocompleteList = true;
        $timeout( function () {
            vm.hideAutocompleteList = false;
        }, 100);
    }

    vm.clearSearchString = function ()
    {
        vm.searchQuery = '';
    }

    vm.onKeyPress = function ( event ) {
        if( event.key == 'Enter' ) {
            vm.goToSearchPage();
        }
    }

    vm.$onInit = function ()
    {
        if( vm.autocompleteEnabled === true ) {
            vm.setWatchers();
        }
    }
}

module.exports = SearchFormController;