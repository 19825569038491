function PurchaseController ( $window, $timeout, $rootScope, $scope, Notification, PurchaseService, CatalogItemService, CityConfigService, AppConfigService )
{
    "ngInject";

    var vm = this;

    vm.updateOrderPriceTimeout = null;

    vm.deliveryForm = null;
    vm.purchaseService = PurchaseService;

    vm.cityConfig = CityConfigService.options.config;

    vm.options = undefined;
    vm.orderData = undefined;
    vm.orderSum = undefined;

    vm.termsOfUse = undefined;

    vm.showPromoForm = false;

    vm.promoCode = undefined;

    vm.setWatchers = function ()
    {
        $rootScope.$on( 'cart-changed', function() {
            $timeout.cancel( vm.updateOrderPriceTimeout );
            vm.updateOrderPriceTimeout = $timeout( function () {
                vm.purchaseService.updateOrderPrice();
                vm.purchaseService.getAvailablePayments();
            }, 500 );

        });

        $scope.$on( 'delivery-type-changed', function () {
            if ( typeof ( CityConfigService.options.config.selfdelivery_points ) != "undefined" ) {
                vm.purchaseService.updateOrderPrice();
                vm.purchaseService.getAvailablePayments();

                vm.orderData.date = "";
            }

            if( vm.orderData.selectedDelivery.selfdelivery == true ) {
                vm.orderData.selectedDelivery.selfdelivery_point = CityConfigService.options.config.selfdelivery_points[0];
            } else {
                vm.orderData.selectedDelivery.selfdelivery_point = "";
            }

            $scope.$emit( 'selfdelivery-point-changed' );
        });

        $scope.$on( 'selfdelivery-point-changed', function () {
            vm.purchaseService.getWorkingHours();
        });
    }

    vm.onSelfDeliveryPointChanged = function () {
        vm.orderData.time = "";
        vm.orderData.date = "";
        $scope.$emit( 'selfdelivery-point-changed' );
    }

    vm.onDeliveryTypeChange = function () {
        vm.orderData.time = "";
        vm.orderData.date = "";
        $scope.$emit( 'delivery-type-changed' );
    }

    vm.onAnonimousSelected = function () {
        if( vm.orderData.is_anonymous === true ) {
            vm.orderData.delivery_customer_name = "";
        }
    }

    vm.onDateSelected = function ( newDate, oldDate )
    {
        vm.orderData.time = "";
        vm.purchaseService.prepareWorkingHours ( newDate );
    };

    vm.beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        vm.purchaseService.beforeCalendarRender ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate );
    }

    vm.checkPromoCode = function ()
    {
        vm.purchaseService.checkPromoCode();
    }

    vm.rejectPromoCode = function ()
    {
        vm.purchaseService.rejectPromoCode();
    }

    vm.updateOrderPrice = function ()
    {
        vm.purchaseService.updateOrderPrice();
    }

    vm.doPurchase = function () {
        if ( !vm.deliveryForm.$valid || ( typeof ( vm.orderData.delivery_region.id ) == "undefined" &&
              vm.orderData.selectedDelivery.selfdelivery == false ) || !vm.orderData.payment_id) {
            Notification.error("Заполните обязательные поля");
            return false;
        }

        if ( !vm.orderData.date || !vm.orderData.time ) {
            Notification.error("Выберите дату и время доставки");
            return false;
        }

        if (!vm.termsOfUse.isCheckTerms)
        {
            return false;
        }

        vm.purchaseService.doPurchase();
    }

    vm.getDateTimePickerConfig = function ()
    {
        return vm.purchaseService.getDateTimePickerConfig();
    }

    vm.$onInit= function ()
    {
        moment.locale("ru");

        PurchaseService.clearOrderData().then( function( result ) {
            vm.options = PurchaseService.options();
            vm.orderData = PurchaseService.orderData();
            vm.orderSum = PurchaseService.orderSum();

            vm.setWatchers();

            return vm.purchaseService.getDeliveryTypes();
        }).then( function ( result ) {
            return vm.purchaseService.getDeliveryRegions();
        }).then( function ( result ) {
            return vm.purchaseService.getAvailablePayments();
        }).then( function ( result ) {
            return vm.purchaseService.getWorkingHours();
        }).then( function (result) {
            vm.purchaseService.initPromoCode();
            vm.purchaseService.checkPromoCode();
        });
    }
}

module.exports = PurchaseController;