function NumbersOnlyDirective () {
  return {
    link: function ( scope, element, attr ) {

      element.on( 'keydown', fromUser );

      function fromUser ( event ) {
        if ( event.which == 64 || event.which == 16 ) {
          return false;
        } else if ( event.which >= 48 && event.which <= 57 ) {
          return true;
        } else if ( event.which >= 96 && event.which <= 105 ) {
          return true;
        } else if ( [ 8, 9, 13, 27, 37, 38, 39, 40 ].indexOf( event.which ) > -1 ) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    }
  }
}

module.exports = NumbersOnlyDirective;