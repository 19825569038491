function CategoryItemController ( CatalogItemService, CityConfigService ) {
    "ngInject";

    var vm = this;
    vm.categoryItem = CatalogItemService;
    vm.cityConfig = CityConfigService;


    vm.$onInit = function () {
        CatalogItemService.init();
    }
}

module.exports = {
    templateUrl: '/partials/components/CategoryItemTemplate.tpl?' + __webpack_hash__,
    controller: CategoryItemController,
    controllerAs: 'categoryItemCtrl',
}