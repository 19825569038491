function SearchFormService( $http, CityConfigService )
{
    "ngInject";

    var data = function()
    {
        return{
            items: [],
            isSearching: false,
        }
    }

    var search = function( searchString )
    {
        data.isSearching = true;
        $http
            .get(
                CityConfigService.options.city.base_url + "/api/handler.php?request=search&limit=5&p=0&string=" + searchString
            )
            .then( function( response ) {
                data.items = response.data.data.items === false ? [] : response.data.data.items ;
                data.isSearching = false;
            });
    }

    return {
        data: data,
        search: search
    }
}

module.exports = SearchFormService;