function AdditionalGoodsService ( $http, $routeParams, CityConfigService )
{
    "ngInject";

    var service = {};
    service.items = [];
    service.categories = {};
    service.currentCategoryId = undefined;

    service.getCategoriesList = function ()
    {
        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getMiscItems',
                currentItemId: $routeParams.itemId
            }
        }).then(
            function success ( response )
            {
                service.categories = response.data.data;
            }
        ).catch( angular.noop );
    };


    return service;
}

module.exports = AdditionalGoodsService;