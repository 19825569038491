function CatalogItemController ( CatalogItemService )
{
    "ngInject";

    var vm = this;
/*    vm.catalogItemService = CatalogItemService;

    vm.catalogItem = CatalogItemService.options;

    vm.init = function ()
    {
        CatalogItemService.init();

        console.log( vm.catalogItem );
    }

    vm.init();*/
}
module.exports = CatalogItemController;