function template () {
  return `      
      <div class="{{catalogFilterFieldRelationCtrl.componentUUID}}">
          <div class="dropdown" ng-class="{open: catalogFilterFieldRelationCtrl.open}">
              <button type="button" class="btn btn-link btn-block dropdown-toggle" ng-click="catalogFilterFieldRelationCtrl.toggleDropdown()">
                  <span class="pull-left">
                     {{::catalogFilterFieldRelationCtrl.field.title}} 
                     <span class="badge" ng-if="catalogFilterFieldRelationCtrl.field.selected.length > 0">
                        {{catalogFilterFieldRelationCtrl.field.selected.length}}
                     </span> 
                  </span>
                  <span>
                      &nbsp;                
                  </span>                  
                  <span>
                     <span class="caret"></span>
                  </span>
              </button>
              <ul class="dropdown-menu">
                  <li ng-repeat="option in catalogFilterFieldRelationCtrl.field.options track by option.id" 
                      ng-class="{optionSelected: (catalogFilterFieldRelationCtrl.isSelected(option) !== -1)}">
                      <div class="checkbox">
                        <label>
                          <input type="checkbox" 
                                 name="fields_filter[{{catalogFilterFieldRelationCtrl.field.name}}][eq][]" 
                                 value="{{::option.id}}" 
                                 ng-checked="catalogFilterFieldRelationCtrl.getIndex(option) !== -1"
                                 ng-click="catalogFilterFieldRelationCtrl.toggleSelectOption(option)" />
                          {{::option.name}}
                        </label>
                      </div>
                  </li>
              </ul>
          </div>
      </div>
  `
}

function CatalogFilterFieldRelationController ($scope) {
  'ngInject'

  let vm = this
  vm.componentUUID = ''
  vm.open = false
  vm.customEvent = false

  vm.toggleSelectOption = function (selectedOption) {
    if ((foundedItem = vm.getIndex(selectedOption)) !== -1) {
      vm.field.selected.splice(foundedItem, 1)
    } else {
      vm.field.selected.push(selectedOption.id)
    }
  }

  vm.getIndex = function (target) {
    return vm.field.selected.findIndex(function (item) {
      return item === target.id
    })
  }

  vm.toggleDropdown = function () {
    if (vm.open) {
      vm.open = false
    } else {
      vm.addEventListener()
      vm.open = true
    }
  }

  vm.addEventListener = function () {
    document.addEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.removeEventListener = function () {
    document.removeEventListener('click', vm.closeOnClickOutsideEvent, false)
  }

  vm.closeOnClickOutsideEvent = function (event) {
    if (!event.target.closest(
      'catalog-filter-field-relation .' + vm.componentUUID)) {
      vm.removeEventListener()
      $scope.$apply(function () {
        vm.open = false
      })
    }
  }

  vm.generateUUID = function () {
    return 'component-uuid' + Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
  }

  vm.$onInit = function () {
    vm.componentUUID = vm.generateUUID()
  }

  vm.$onDestroy = function () {
    vm.removeEventListener()
  }
}

module.exports = {
  template: template(),
  controller: CatalogFilterFieldRelationController,
  controllerAs: 'catalogFilterFieldRelationCtrl',
  bindings: {
    field: '=',
  },
}
