function MobileHeaderController ( $rootScope, AppConfigService, CityConfigService ) {
  "ngInject";

  var vm = this;

  vm.appConfig = AppConfigService;
  vm.cityConfig = CityConfigService;

  vm.level2 = false;
  vm.mobileMenuShowed = false;

  vm.searchFormShowed = false;
  vm.searchStr = "";

  $rootScope.$on( '$routeChangeStart', function( event, next, current )
  {
    vm.mobileMenuShowed = false;
    vm.searchFormShowed = false;
    vm.level2 = false;
  });

  vm.toggleSearchForm = function () {
    vm.searchFormShowed = !vm.searchFormShowed;
  }

  vm.toggleMobileMenu = function () {
    vm.mobileMenuShowed = !vm.mobileMenuShowed;
  }
}

module.exports = {
  templateUrl: '/partials/components/MobileHeaderTemplate.tpl?' + __webpack_hash__,
  controller: MobileHeaderController,
  controllerAs: 'mobileHeaderCtrl'
}