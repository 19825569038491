var angular = require('angular')

angular.module('flowersApp').
  service('AppConfigService', require('./AppConfigService')).
  service('EcommerceService', require('./EcommerceService')).
  service('AppService', require('./AppService')).
  service('CityConfigService', require('./CityConfigService'))

  //.service( "$exceptionHandler", require( "./ExceptionHandler" ) )

  .service('CartService', require('./CartService')).
  service('MetaService', require('./MetaService')).
  service('FastOrderService', require('./FastOrderService')).
  service('StatusCodeService', require('./StatusCodeService')).
  service('BannersService', require('./BannersService')).
  service('CategoriesService', require('./CategoriesService')).
  service('ContentService', require('./ContentService')).
  service('NotFoundService', require('./NotFoundService')).
  service('CatalogItemListService', require('./CatalogItemListService')).
  service('PriceFilterService', require('./PriceFilterService')).
  service('CatalogItemService', require('./CatalogItemService')).
  service('PurchaseService', require('./PurchaseService')).
  service('AdditionalGoodsService', require('./AdditionalGoodsService')).
  service('SuccessPaymentService', require('./SuccessPaymentService')).
  service('HelpersService', require('./HelpersService')).
  service('BouquetPhotoService', require('./BouquetPhotoService')).
  service('MainMenuService', require('./MainMenuService')).
  service('SearchFormService', require('./SearchFormService')).
  service('SearchService', require('./SearchService')).
  service('ReviewsService', require('./ReviewsService')).
  service('PromoCodeService', require('./PromoCodeService')).
  service('CityListService', require('./CityListService')).
  service('FreeDeliveryRegionsService', require('./FreeDeliveryRegionsService')).
  service('CatalogFilterComponentService', require('./CatalogFilterComponentService')).
  service('RandomSeoLinksService', require('./RandomSeoLinksService')).
  service('GeoLocationService', require('./GeoLocationService')).
  service('SeoMenuService', require('./SeoMenuService'))
