function RandomSeoLinksService ($http, CityConfigService) {
  'ngInject'

  let getRandomSeoLinks = function (linksCount, catalogItemId) {
    let requestParams = {
      request: 'getRandomSeoFilterLinks',
      linksCount: linksCount,
      catalogItemId: catalogItemId
    }

    return $http({
      url: CityConfigService.options.city.base_url + "/api/handler.php",
      method: 'GET',
      params: requestParams,
      cache: false
    })
  }

  return {
    getRandomSeoLinks: getRandomSeoLinks
  }
}

module.exports = RandomSeoLinksService