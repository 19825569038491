function DropzoneDirective ()
{
    return {
        restrict: 'A',
        scope: {
            config: '=',
        },
        link: function (scope, element, attrs)
        {
            var dropzone = new Dropzone( element[0], scope.config.options );
            angular.forEach( scope.config.eventHandlers, function (handler, event) {
                dropzone.on(event, handler);
            });
        }
    }
}

module.exports = DropzoneDirective;