function FastOrderController ( FastOrderService, $uibModal )
{
    "ngInject";

    var vm = this;

    vm.fastOrderService = FastOrderService;
    vm.options = vm.fastOrderService.options;
    vm.fastOrderForm = undefined;

    vm.oneClickOrderForm = function ( item, mode )
    {
        vm.options.itemData = item;
        vm.options.clientPhone = '';
        vm.options.mode = mode;

        modalInstance = $uibModal.open({
            animation: true,
            controller: "FastOrderController",
            controllerAs: "fastOrderController",
            windowClass: "one-click-order-form",
            size: "sm",
            templateUrl: "/partials/includes/one_click_order_form.tpl?" + __webpack_hash__
        });
    }

    vm.makeFastOrder = function()
    {
        vm.fastOrderService.makeFastOrder().then( function ( previousResult )
        {
            if( previousResult === true ){
                modalInstance.close();
            }
        });
    }
}

module.exports = FastOrderController;