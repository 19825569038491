function HelpersController( HelpersService )
{
    "ngInject";

    var vm = this;

    vm.goToAnchor = function( anchorName )
    {
        HelpersService.goToAnchor( anchorName );
    }
}

module.exports = HelpersController;