function template() {
  return `
    <div class="row catalog-filter" ng-if="catalogFilterCtrl.onLoad === false"  ng-class="{'in' : catalogFilterCtrl.showFiltersOnMobile}">
        <div class="col-xs-12 col-sm-12">
            <div class="panel">
                <div class="panel-body">
                    <div class="form-inline">
                        <div class="row">
                            <div class="col-xs-12 col-sm-2 col-md-2 col-lg-1 filter-header">
                                <strong class="pull-left filter-title">
                                    Фильтры <span class="badge visible-xs-inline-block hidden-sm">{{catalogFilterCtrl.getSelectedFiltersCount()}}</span>
                                </strong>
                                <button type="button" class="btn btn-link visible-xs-inline-block hidden-sm pull-right text-left" ng-click="catalogFilterCtrl.showFiltersOnMobile = !catalogFilterCtrl.showFiltersOnMobile">
                                    <span ng-if="!catalogFilterCtrl.showFiltersOnMobile">Показать</span>
                                    <span ng-if="catalogFilterCtrl.showFiltersOnMobile">Скрыть</span>
                                </button>
                            </div>
                            <div class="col-xs-12 col-sm-7 col-md-8 col-lg-9 filters-list">
                                <catalog-filter-field-price
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('price')"
                                        field="catalogFilterCtrl.getField('price')">
                                </catalog-filter-field-price>
                                <catalog-filter-field-boolean
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('popular_product')"
                                        field="catalogFilterCtrl.getField('popular_product')">
                                </catalog-filter-field-boolean>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('sostav')"
                                        field="catalogFilterCtrl.getField('sostav')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('komu')"
                                        field="catalogFilterCtrl.getField('komu')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('povod')"
                                        field="catalogFilterCtrl.getField('povod')">
                                </catalog-filter-field-relation>
    
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('tsvet')"
                                        field="catalogFilterCtrl.getField('tsvet')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('tip_buketa')"
                                        field="catalogFilterCtrl.getField('tip_buketa')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('forma_buketa')"
                                        field="catalogFilterCtrl.getField('forma_buketa')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('kolichestvo')"
                                        field="catalogFilterCtrl.getField('kolichestvo')">
                                </catalog-filter-field-relation>
                                <catalog-filter-field-relation
                                        class="form-group"
                                        ng-if="catalogFilterCtrl.isFieldExist('sort')"
                                        field="catalogFilterCtrl.getField('sort')">
                                </catalog-filter-field-relation>
                            </div>
                            <div class="col-xs-12 col-sm-1 col-md-1 col-lg-2 text-center filter-controls">
                                <div class="form-group">
                                    <button type="button" class="btn btn-default btn-block" ng-click="catalogFilterCtrl.doFilter()">
                                        <i class="fa fa-filter" aria-hidden="true"></i>
                                        Применить
                                    </button>
                                </div>
    
                                <div class="form-group text-center">
                                    <a class="btn bnt-link clear-filter" ng-click="catalogFilterCtrl.reset()">
                                        Очистить
                                    </a>
                                </div>
    
                            </div>
                        </div>
    <!--                    <hr/>
                        <div class="row">
                            <div class="col-xs-12">
                                <strong class="pull-left filter-title">
                                    Сортировка:
                                </strong>
                            </div>
                        </div>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
 
  `
}

function CatalogFilterComponentController ($rootScope, $scope, $location, CatalogFilterComponentService) {
  'ngInject'

  let vm = this

  vm.catalogFilterFields = []
  vm.onLoad = true
  vm.showFiltersOnMobile = false

  /**
   *  Метод для проверки существования поля
   * @param fieldName
   * @returns {boolean}
   */
  vm.isFieldExist = function (fieldName) {
    return vm.catalogFilterFields.findIndex(function (item) {
      return item.name === fieldName
    }) !== -1
  }

  /**
   * Метод для получения поля
   * @param fieldName
   * @returns {T}
   */
  vm.getField = function (fieldName) {
    return vm.catalogFilterFields.find(function (item) {
      return item.name === fieldName
    })
  }

  /**
   * Метод для подготовки строки параметров
   * @param paramsToPrepare
   * @returns {string}
   */
  vm.prepareFilterParams = function (paramsToPrepare) {
    let preparedParams = []

    paramsToPrepare.forEach(function (item, index) {
      switch (item.type) {
        case 'relation': {
          if ((preparedFieldParam = vm.prepareRelationParam(item)) !== false) {
            preparedParams.push(preparedFieldParam)
          }
          break
        }
        case 'boolean': {
          if (item.selected) {
            preparedParams.push(vm.prepareBooleanParam(item))
          }
          break
        }
        case 'price': {
          preparedParams.push(vm.preparePriceParam(item))
          break
        }

      }
    })

    preparedParams = preparedParams.join('&').trim('&')

    return preparedParams
  }

  /**
   * Метод для подготовки поля типа "Price"
   * @param paramData
   * @returns {string}
   */
  vm.preparePriceParam = function (paramData) {
    let preparedParam = []

    preparedParam.push(
      `fields_filter[${paramData.name}][0]=${paramData.selected.min}`)
    preparedParam.push(
      `fields_filter[${paramData.name}][1]=${paramData.selected.max}`)

    return preparedParam.join('&').trim('&')
  }

  /**
   * Метод для подготовки поля типа "Relation"
   * @param paramData
   * @returns {string|boolean}
   */
  vm.prepareRelationParam = function (paramData) {
    if (paramData.selected.length === 0) {
      return false
    }

    let preparedParam = []

    let paramName = `fields_filter[${paramData.name}][eq]`
    paramName += (paramData.multiple ? `[]` : ``)

    paramData.selected.forEach(function (item, index) {
      preparedParam.push(`${paramName}=${item}`)
    })

    return preparedParam.join('&').trim('&')
  }

  /**
   * Метод для подготовки поля типа  "Boolean"
   * @param paramData
   * @returns {string}
   */
  vm.prepareBooleanParam = function (paramData) {
    return `fields_filter[${paramData.name}][eq]=` +
      (paramData.selected ? '1' : '0')
  }

  /**
   * Метод для выполнения фильтрации
   */
  vm.doFilter = function () {
    $location.search(vm.prepareFilterParams(vm.catalogFilterFields)).replace()
    $rootScope.$broadcast('catalog-filter-apply')
  }

  /**
   * Метод для сброса параметров фильтрации
   */
  vm.reset = function () {
    vm.catalogFilterFields.forEach(function (item, index) {
      switch (item.type) {
        case 'boolean': {
          item.selected = false
          break
        }
        case 'relation': {
          item.selected = []
          break
        }
        case 'price': {
          item.selected = item.options
          break
        }
      }
    })

    vm.doFilter()
  }

  /**
   * Метод для получения кол-ва выбранных фильтров
   * @returns {number}
   */
  vm.getSelectedFiltersCount = function () {
    let selectedFiltersCount = 0
    vm.catalogFilterFields.forEach(function (item, index) {
      switch (item.type) {
        case 'boolean': {
          if (item.selected || item.selected == 1) {
            selectedFiltersCount++
          }
          break
        }
        case 'relation': {
          if (item.selected.length > 0) {
            selectedFiltersCount++
          }
          break
        }
        case 'price': {
          if (item.selected.min != item.options.min || item.selected.max != item.options.max) {
            selectedFiltersCount++
          }
          break
        }
      }
    })

    return selectedFiltersCount
  }

  /**
   *  Метод выполняющийся при инициализации компонента
   */
  vm.$onInit = function () {
    vm.onLoad = false

    CatalogFilterComponentService.getFilterFields(vm.catalogId).
      then(function (response) {
        vm.catalogFilterFields = response.data
        vm.onLoad = false
      })
  }
}


module.exports = {
  template: template(),
  controller: CatalogFilterComponentController,
  controllerAs: 'catalogFilterCtrl',
  bindings: {
    catalogId: '<'
  },
}
