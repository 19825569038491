function BannersService ( $http, $q, CityConfigService )
{
    "ngInject";

    var options = {
        items: []
    };

    var clearBannersList = function ()
    {
        var deffered = $q.defer();

        options.items = [];
        deffered.resolve( options.items );

        return deffered.promise;
    }

    var loadBanners = function ()
    {
        options.items = [];

        return $http
            .get( CityConfigService.options.city.base_url + "/api/handler.php?request=getBanners&bannerPlaces=top_banner" )
            .then( function ( result )
            {
                if ( typeof( result.data.data[0] ) != "undefined" && result.data.data.length > 0 )
                {
                    options.items = result.data.data;
                }

                return options.items;
            }).catch( angular.noop );
    }

    return {
        options: options,
        loadBanners: loadBanners,
        clearBannersList: clearBannersList,
    }
}

module.exports = BannersService;