function FreeDeliveryRegionsController (FreeDeliveryRegionsService, CityConfigService) {
  'ngInject'

  var vm = this
  vm.freeDeliveryRegionsList = {}
  vm.showAll = false
  vm.slug = CityConfigService.getSlug()

  vm.$onInit = function () {
    FreeDeliveryRegionsService.getFreeDeliveryRegions(vm.catalogItemId).
      then(function (response) {
        vm.freeDeliveryRegionsList = response.data.data
      })
  }

  vm.toggleRegionsList = function ($event) {
    $event.preventDefault()
    vm.showAll = !vm.showAll
  }
}

module.exports = {
  templateUrl: '/partials/free_delivery_regions_template.tpl?' +
    __webpack_hash__,
  controller: FreeDeliveryRegionsController,
  controllerAs: 'freeDeliveryRegionsCtrl',
  bindings: {
    catalogItemId: '<',
  },
}