function FreeDeliveryRegionsService ($http, CityConfigService) {
  'ngInject'

  var getFreeDeliveryRegions = function (catalogItemId) {
    var data = {
      request: 'getFreeDeliveryRegions',
      catalogItemId: catalogItemId,
    }

    return $http({
      url: CityConfigService.options.city.base_url + "/api/handler.php",
      method: "GET",
      params: data,
      cache: false
    })
  }

  return {
    getFreeDeliveryRegions: getFreeDeliveryRegions,
  }
}

module.exports = FreeDeliveryRegionsService