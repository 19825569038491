function CatalogItemService ( $http, $routeParams, $location, MetaService, StatusCodeService, CityConfigService )
{
    "ngInject";

    var options = {
        loading: {
            catalogItem: false
        },
        itemData: {}
    }

    var getCatalogItemData = function ()
    {
        options.loading.catalogItem = true;
        options.itemData = {};

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getCatalogItemById',
                catalogItemId: $routeParams.itemId
            },
            cache: false
        })
            .then (function ( response ) {
                    options.loading.catalogItem = false;
                    options.itemData = response.data.data.itemData;

                    StatusCodeService.setStatusCode('200');

                    MetaService.setMetaTags({
                        title: "Заказать " + ((options.itemData.buket) ? " Букет " : "") + "\"" + options.itemData.name + "\"",
                        cityName: CityConfigService.options.city.name,
                        img: CityConfigService.options.city.base_url + options.itemData.image
                    });
            })
            .catch( function () {
                $location.url( '/' + CityConfigService.options.city.slug + '/404');
            });
    };

    var init = function () {
        moment.locale("ru");
        getCatalogItemData();
    };

    return {
        options: options,
        init: init,
        getCatalogItemData: getCatalogItemData
    }

}
module.exports = CatalogItemService;