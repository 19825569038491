function fancyBoxDirective()
{
    return {
        restrict: 'A',

        link: function(scope, element, attrs){
            if( scope.$last ) {
                $('[data-fancybox]').fancybox();
            }
        }
    }
}

module.exports = fancyBoxDirective;