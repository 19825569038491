function FastOrderService ( $http, $location, Notification, CityConfigService, AdditionalGoodsService, HelpersService, AppConfigService )
{
    'ngInject';

    var options = {
        "clientPhone" : "",
        "itemData": [],
        "network": "",
        "fastOrderProcessing": false,
        "mode": false,
    };

    var getItems = function ()
    {
        var items = [];

        switch ( options.mode )
        {
            case "list-item":
                break;
            case "page-item":
                items = AdditionalGoodsService.getAllStoredItems();
                break;
        }

        items.push( options.itemData )

        return items;
    };

    var makeFastOrder = function () {

        if (options.clientPhone == '') {
            Notification.error("Введите номер телефона");
            return false;
        }

        items = getItems();

        options.fastOrderProcessing = true;

        options.network = AppConfigService.options.network;

        return $http
        .post(
            CityConfigService.options.city.base_url + "/api/handler.php",
            {
                request: 'oneClickOrder',
                data: {
                    'clientPhone': options.clientPhone,
                    'network': options.network,
                },
                items: items
            }
        )
        .then( function ( data ) {

            response = data.data.data;

            if( response.result === false ) {
                Notification.error("Неверно введен номер телефона");
                options.fastOrderProcessing = false;

                return false;
            }

            redirect = CityConfigService.options.city.slug + HelpersService.removeHashBang( response.redirect );
            options.fastOrderProcessing = false;

            $location.url( redirect , false);

            return true;
        })
          .catch( angular.noop )  ;
    }

    return {
        options: options,
        makeFastOrder: makeFastOrder
    }
}

module.exports = FastOrderService;