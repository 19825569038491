function BouquetPhotoController ( $scope, $location, BouquetPhotoService, CityConfigService, HelpersService, Notification, MetaService, StatusCodeService )
{
    "ngInject";

    var vm = this;
    vm.options = BouquetPhotoService.options;
    vm.orderForm = undefined;

    vm.formSubmitting = false;

    vm.dateTimePickerConfig = {
        startView: 'day',
        minView: 'day'
    };

    vm.schedule = "";
    vm.exceptions = [];

    vm.time = "";
    vm.timePeriods = undefined;

    /**
     * Метод устанавливающий слушателей событий
     */
    vm.setWatchers = function () {

        $scope.$on( 'selfdelivery-point-changed', function () {
            vm.getWorkingHours();
        });

        $scope.$on( 'delivery-type-changed', function () {
            if ( typeof ( CityConfigService.options.config.selfdelivery_points ) != "undefined" ) {
                vm.options.orderData.date = "";
            }

            if( vm.options.orderData.selectedDelivery.selfdelivery == true ) {
                vm.options.orderData.selectedDelivery.selfdelivery_point = CityConfigService.options.config.selfdelivery_points[0];
            } else {
                vm.options.orderData.selectedDelivery.selfdelivery_point = "";
            }

            $scope.$emit( 'selfdelivery-point-changed' );
        });
/*
        $scope.$watch(
            function ()
            {
                return vm.options.orderData.selectedDelivery;
            },
            function ( newValue, oldValue )
            {
                if ( ( typeof ( newValue ) != "undefined" ) && ( typeof ( CityConfigService.options.config.selfdelivery_points ) != "undefined" ) )
                {
                    if ( newValue.selfdelivery == false )
                    {
                        vm.options.orderData.selectedDelivery.selfdelivery_point = CityConfigService.options.config.selfdelivery_points[0];
                    }
                }
            });

        $scope.$watch(
            function ()
            {
                return vm.options.orderData.selectedDelivery.selfdelivery_point;
            },
            function ( newValue, oldValue )
            {
                if( newValue != oldValue )
                {
                    vm.getWorkingHours();
                }
            });

        $scope.$watch(
            function ()
            {
                return vm.schedule;
            },
            function ( newValue, oldValue )
            {
                if ( vm.options.orderData.date != undefined )
                {
                    vm.options.orderData.time = undefined;
                    prepareWorkingHours( vm.options.orderData.date );
                }
            }
        );
*/
    }

    /**
     * Метод для получения расписания работы и исключений
     */
    vm.getWorkingHours = function ()
    {
        BouquetPhotoService.getWorkingHours().then(
            function ( response )
            {
                var data = response.data.data;
                vm.schedule = data.schedule === "" ? "" : data.schedule;
                vm.exceptions = data.exceptions === "" ? [] : data.exceptions;
            }
        );
    }

    /**
     * Метод очищающий выбранную дату и время при смене точки самовывоза
     */
    vm.onSelfDeliveryPointChanged = function () {
        vm.options.orderData.time = "";
        vm.options.orderData.date = "";
        $scope.$emit( 'selfdelivery-point-changed' );
    }

    /**
     * Метод очищающий выбранную дату и время при смене типа доставки
     */
    vm.onDeliveryTypeChanged = function () {
        vm.options.orderData.time = "";
        vm.options.orderData.date = "";
        $scope.$emit( 'delivery-type-changed' );
    }

    /**
     * Метод очищающий переменную с именем заказчика при установке галочки "Анонимно"
     */
    vm.onAnonimousSelected = function () {
        if( vm.options.orderData.is_anonymous === true ) {
            vm.options.orderData.delivery_customer_name = "";
        }
    }

    /**
     * Метод очищающий выбранное время и запрашивающий расписание для новой даты
     *
     * @param newDate
     * @param oldDate
     */
    vm.onDateSelected = function ( newDate, oldDate ) {
        vm.options.orderData.time = "";
        prepareWorkingHours ( newDate );
    };

    /**
     * Метод для подготовки списка часов работы
     */
    var prepareWorkingHours = function ( date )
    {
        if ( vm.schedule == '' )
        {
            return;
        }

        vm.timePeriods = [];

        vm.timePeriods.push('');

        date = new Date(date);
        day = date.getDate();
        month = date.getMonth();
        dayNum = date.getDay();

        var dateObject = new Date();
        var nowDate = {
            dayNum: dateObject.getDay(),
            day: dateObject.getDate(),
            month: dateObject.getMonth(),
            year: dateObject.getFullYear()
        };

        var isToday = false;
        if ( nowDate.day == day )
        {
            isToday = true;
        }

        var dateObject = new Date();
        var timeMatrix = [];

        var schedule = HelpersService.explode( ";", vm.schedule );

        var start_hour = 0;
        var end_hour = 0;
        var interval = 0;

        switch( dayNum )
        {
            case 0:
            {
                start_hour = schedule[7];
                end_hour = schedule[8];
                interval = schedule[9];
            }

            case 6:
            {
                start_hour = schedule[4];
                end_hour = schedule[5];
                interval = schedule[6];

                break;
            }

            default:
            {
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }

        }

        vm.exceptions.forEach( function ( item, index, source ) {

            var exc_day = item.date.substr( 0, 2 );
            var exc_month = item.date.substr( 3, 2 );

            if ( parseInt( exc_day ) == day && parseInt( exc_month ) == month + 1 )
            {
                schedule = HelpersService.explode( ";", item.exc_schedule );
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }
        });

        var build_time = schedule[0];

        var m = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( start_hour.substr( 0, 2 ) ), parseInt( start_hour.substr( 3, 2 ) ) ) );
        var mEnd = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( end_hour.substr( 0, 2 ) ), parseInt( end_hour.substr( 3, 2 ) ) ) );
        var now = moment( CityConfigService.options.config.server_time );

        var i = 0;

        while ( ( m.unix() + interval * 60 ) <= mEnd.unix() )
        {

            hours = ( m.hours() < 10 ) ? "0" + m.hours() : m.hours();
            minutes = ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes();
            m.add( interval, 'minutes' );

            calculatedValue = now.unix() + build_time * 60;

            if ( m.unix() >= calculatedValue )
            {
                time = hours + ":" + minutes + " - " + ( ( m.hours() < 10 ) ? "0" + m.hours() : m.hours() ) + ":" + ( ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes() );
                if ( i == 0 )
                {
                    vm.options.orderData.time = time;
                }

                vm.timePeriods.push( time );
                i++;
            }
        }

        vm.time = vm.timePeriods[0];
    };

    /*
        Метод для определения вида отрисовки и запуска соответствющего метода
     */
    vm.beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate ) {
        switch ( $viewType ) {
            case 'year': {
                renderYearView ( $datePickerDates );
                break;
            };

            case 'month': {
                renderMonthView ( $datePickerDates );
                break;
            }

            case 'day': {
                renderDayView ( $datePickerDates );
                break;
            }
        }
    };

    /*
        Метод для отрисовки вида "Год"
     */
    var renderYearView = function ( $datePickerDates ) {
        var currentDate = new Date();

        $datePickerDates.forEach( function ( item, index, source ) {
            var datePickerDate = new Date( item.utcDateValue );
            if ( currentDate.getYear() > datePickerDate.getYear() ) {
                item.selectable = false;
            }
        });
    };

    /*
        Метод для отрисовки вида "Месяц"
     */
    var renderMonthView = function ( $datePickerDates ) {
        var currentDate = new Date();

        $datePickerDates.forEach( function ( item, index, source ) {
            var datePickerDate = new Date( item.utcDateValue );
            var isSelectable = true;

            if ( currentDate.getFullYear() > datePickerDate.getFullYear() ) {
                isSelectable = false;
            }

            if ( currentDate.getFullYear() == datePickerDate.getFullYear() && currentDate.getMonth() > datePickerDate.getMonth() ) {
                isSelectable = false;
            }

            item.selectable = isSelectable;
        });
    };

    /*
        Метод для отрисовки вида "День"
     */
    var renderDayView = function ( $datePickerDates )
    {
        var currentDate = new Date();
        currentDate.setHours( 0, 0, 0, 0 );

        $datePickerDates.forEach( function ( item, index, source ) {
            var datePickerDate = new Date( item.utcDateValue );
            datePickerDate.setHours( 0, 0, 0, 0 );

            if ( currentDate > datePickerDate ) {
                item.selectable = false;
            }
        });
    };

    vm.doPurchase = function ()
    {

        if ( vm.options.orderData.images.length == 0 )
        {
            Notification.error( "Выберите минимум 1 изображение" );
            return;
        }

        if ( !vm.orderForm.$valid || ( typeof ( vm.options.orderData.delivery_region.id ) == "undefined" && vm.options.orderData.selectedDelivery.selfdelivery == false ) )
        {
            Notification.error("Заполните обязательные поля");
            return;
        }

        if ( !vm.options.orderData.date || !vm.options.orderData.time )
        {
            Notification.error("Выберите дату и время доставки");
            return;
        }

        if (!vm.termsOfUse.isCheckTerms)
        {
            return;
        }

        vm.formSubmitting = true;

        BouquetPhotoService.doPurchase().then(
            function ( response ) {
                var redirect = response.data.data.redirect;

                if ( redirect.indexOf( '://' ) == -1 ) {
                        urlArr = redirect.split("/");
                        urlArr.splice(0, 1);
                        $location.url(
                            "/" + CityConfigService.options.city.slug + "/" +
                            urlArr.join("/"));

                } else {
                        $window.location.href = redirect;
                }
                vm.formSubmitting = false;
            },
            function ( reason ) {
                Notification.error( "Во время оформления заказа произошла ошибка. Пожалуйста, попробуйте оформить заказ ещё раз. Спасибо!" );
                vm.formSubmitting = false;
            }
        );
    }

    /*
        Метод для инициализации контроллера
     */
    vm.init = function ()
    {
        moment.locale("ru");

        BouquetPhotoService.clearOrderData();
        vm.setWatchers();

        BouquetPhotoService.getDeliveryTypes().then(function ( response ) {
            vm.orderForm.deliveryTypes = response.data.data;
            if ( vm.orderForm.deliveryTypes.length > 0 )
            {
                vm.options.orderData.selectedDelivery = vm.orderForm.deliveryTypes[1];
            }

            if( CityConfigService.options.config.selfdelivery_points.length > 0 )
            {
                vm.options.orderData.selectedDelivery.selfdelivery_point = CityConfigService.options.config.selfdelivery_points[0];
            }
        })

        BouquetPhotoService.getDeliveryRegions().then(function ( response )
        {
            vm.orderForm.deliveryRegions = response.data.data;
            vm.options.orderData.delivery_region = vm.orderForm.deliveryRegions[0].id;

            vm.getWorkingHours();
        });

        StatusCodeService.setStatusCode('200');

        MetaService.setMetaTags({
            title: "Заказать букет по фотографии",
            cityName: CityConfigService.options.city.name
        });
    }

    vm.init();
}

module.exports = BouquetPhotoController;