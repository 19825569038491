function StickyDirective()
{
    return {
        restrict: 'A',
        controllerAs: 'stickyCtrl',
        controller: function ( $window, $scope ) {
            "ngInject";

            var vm = this;
            vm.isDisplayed = false;
            vm.element = null;
            vm.fixed = false;
            vm.w = angular.element( $window );
            vm.size = 0;
            vm.top = 0;


            vm.toggleStickyNav = function()
            {
                if( !vm.fixed && $window.pageYOffset > vm.top && vm.isDisplayed )
                {
                    $scope.$apply( function() {
                        vm.fixed = true;
                    });
                } else if( vm.fixed && $window.pageYOffset <= vm.top && vm.isDisplayed )
                {
                    $scope.$apply( function() {
                        vm.fixed = false;
                    });
                }
            }

            vm.setWatchers = function ()
            {
                $scope.$watch( function ()
                {
                    return getComputedStyle( vm.element[0], null ).display;
                }, function ( newValue, oldValue )
                {
                    if( newValue !== oldValue )
                    {
                        vm.isDisplayed = newValue === 'block' ? true : false;
                    }
                });

                $scope.$watch( function()
                {
                    return vm.element[0].getBoundingClientRect().top + $window.pageYOffset;
                }, function( newValue, oldValue )
                {
                    if( newValue !== oldValue && !vm.fixed && vm.isDisplayed )
                    {
                        vm.top = newValue;
                    }
                });
            }

            vm.setElementTop = function ()
            {
                //vm.top = vm.element[0].getBoundingClientRect().top;
                vm.top = vm.element[0].getBoundingClientRect().top + $window.pageYOffset;
            }

            vm.stickyNavResize = function ()
            {
                vm.fixed = false;
                vm.isDisplayed = getComputedStyle( vm.element[0], null ).display === 'block' ? true : false;
                vm.setElementTop();
                vm.toggleStickyNav();
            }

            vm.init = function ( element )
            {
                vm.element = element;
                vm.size = vm.element[0].clientHeight;
                vm.isDisplayed = getComputedStyle( vm.element[0], null ).display === 'block' ? true : false;

                vm.setElementTop();

                //console.log( element, vm.isDisplayed );

                vm.setWatchers();

                vm.w.bind( 'resize', vm.stickyNavResize );
                vm.w.bind( 'scroll', vm.toggleStickyNav );
            }
        },
        link: function ( scope, element, attrs )
        {
            angular.element( document ).ready( function(){
                scope.stickyCtrl.init( element );
            });
        }
    }
}

module.exports = StickyDirective;