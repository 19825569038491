function SeoMenuService ( $http, CityConfigService ) {

    "ngInject";

    var seoMenu = {
        seoMenuLinks: []
    };

    var getSeoMenu = function () {
        var requestParams = {
            request: 'getSeoMenu'
        };

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "POST",
            params: requestParams,
            cache: false
        }).then(function (response) {
           seoMenu.seoMenuLinks = editSeoMenuLink(response.data.data);
        });
    };

    var editSeoMenuLink = function ( seoMenu ) {
        var editedSeoMenu = seoMenu;
        seoMenu.forEach( function ( column, columnIndex ) {
            column.values.forEach( function ( seoLink, seoLinkIndex ) {
                editedSeoMenu[columnIndex].values[seoLinkIndex].url = CityConfigService.options.city.slug + seoLink.url;
            });
        });

        return editedSeoMenu;
    }

    return {
        getSeoMenu: getSeoMenu,
        seoMenu: seoMenu
    }
}

module.exports = SeoMenuService;