function SuccessPaymentController ( SuccessPaymentService, CityConfigService, MetaService )
{
    "ngInject";

    var vm = this;
    vm.successPaymentService = SuccessPaymentService;
    vm.values = vm.successPaymentService.values;

    vm.setMetaData = function () {
        MetaService.setMetaTags({
            title: 'Заказ оформлен!',
            cityName: CityConfigService.options.city.name
        });
    }

    vm.init = function ()
    {
        vm.successPaymentService.init();
        vm.setMetaData();
    }

    vm.init();
}

module.exports = SuccessPaymentController;