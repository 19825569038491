function HelpersService ( $location, $anchorScroll )
{
    "ngInject";

    /**
     * Метод для прокрутки страницы к якорю
     *
     * @param anchorName {string}
     * @returns {boolean}
     */
    var goToAnchor = function( anchorName )
    {
        if( anchorName === '' )
        {
            return false;
        }

        $anchorScroll( anchorName );
    }

    /**
     * Метод для удаления решетки из ссылки
     *
     * @param url
     * @returns {*}
     */
    var removeHashBang = function( url )
    {
        return url.replace( "\/#!", "" )
    }

    /**
     * Метод для разделения строки в соответствии с шаблоном
     *
     * @param delimiter
     * @param string
     * @returns {{'0': string}|null|boolean|string[]}
     */
    var explode = function ( delimiter, string )
    {
        var emptyArray = {0: ''};

        if ( arguments.length != 2
            || typeof arguments[0] == 'undefined'
            || typeof arguments[1] == 'undefined' )
        {
            return null;
        }

        if ( delimiter === ''
            || delimiter === false
            || delimiter === null )
        {
            return false;
        }

        if ( typeof delimiter == 'function'
            || typeof delimiter == 'object'
            || typeof string == 'function'
            || typeof string == 'object' )
        {
            return emptyArray;
        }

        if ( delimiter === true )
        {
            delimiter = '1';
        }

        return string.toString().split( delimiter.toString() );
    }

    return {
        goToAnchor: goToAnchor,
        removeHashBang: removeHashBang,
        explode: explode
    }
}

module.exports = HelpersService;