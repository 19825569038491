require('./vendor');

if( process.env.NODE_ENV !== 'development' )
{
    window.bugsnagClient = bugsnag({
        apiKey: __bugsnag_api_key__,
        appVersion: __commit_hash__,
        releaseStage: process.env.NODE_ENV
    });
}

angular
.module('flowersApp', [ 'ngAnimate', 'ui.bootstrap-slider', 'ngRoute', 'ui.bootstrap', 'ngSanitize', 'ui.bootstrap.datetimepicker', 'yaMap', 'ui.mask', 'ui-notification', 'ng', 'yandex-metrika', 'ng.deviceDetector', 'ngCookies', 'infinite-scroll'])
.config( function ( $qProvider, $httpProvider, $routeProvider, $locationProvider, NotificationProvider ) {
    'ngInject';

    $qProvider.errorOnUnhandledRejections(false);

    $httpProvider.defaults.withCredentials = true;
    $httpProvider.defaults.cache = true;

    $locationProvider.html5Mode( true );
    $locationProvider.hashPrefix( '!' );


    $routeProvider
        .when('/', {
            templateUrl: "/partials/index_content.tpl?" + __webpack_hash__,
            controller: "IndexController",
            controllerAs: "indexCtrl"
        })

        .when('/:slug', {
            templateUrl: '/partials/index_content.tpl?' + __webpack_hash__,
            controller: 'IndexController',
            controllerAs: 'indexCtrl',
        })

        .when( "/:slug/reviews", {
            templateUrl: '/partials/pages/reviews.tpl?' + __webpack_hash__,
            controller: 'ReviewsController',
            controllerAs: 'reviewsCtrl'
        })

        .when( "/:slug/cart", {
            templateUrl: '/partials/cart_page.tpl?' + __webpack_hash__,
            controller: 'CartController',
            controllerAs: 'cartCtrl',
        })

        .when( '/:slug/search', {
            templateUrl: "/partials/search_items.tpl?" + __webpack_hash__,
            controller: "SearchController",
            controllerAs: "searchCtrl"
        })

        .when("/:slug/bouquet-photo", {
            templateUrl: "/partials/bouquet_photo.tpl?" + __webpack_hash__,
            controller: "BouquetPhotoController",
            controllerAs: "bouquetPhotoCtrl"
        })

        .when('/:slug/catalog/:categoryId', {
            templateUrl: '/partials/inner_catalog_items.tpl?' + __webpack_hash__,
            controller: "CatalogItemListController",
            controllerAs: "catalogItemListCtrl"
        })

        .when('/:slug/catalog/:categoryId/:minPrice/:maxPrice', {
            templateUrl: '/partials/inner_catalog_items.tpl?' + __webpack_hash__,
            controller: "CatalogItemListController",
            controllerAs: "catalogItemListCtrl"
        })

        .when('/:slug/item/:itemId', {
            templateUrl: '/partials/inner_catalog_item_view.tpl?' + __webpack_hash__,
            controller: 'CatalogItemController',
            controllerAs: 'catalogItemCtrl'
        })

        .when('/:slug/collect', {
            templateUrl: '/partials/collect.tpl?' + __webpack_hash__,
            controller: 'collectController'
        })

        .when('/:slug/page/:name', {
            templateUrl: '/partials/content.tpl?' + __webpack_hash__,
            controller: 'ContentController',
            controllerAs: 'contentCtrl'
        })

        .when('/:slug/success/', {
            templateUrl: '/partials/success_payment_page.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when( '/:slug/transaction/', {
            templateUrl: '/partials/pages/transaction.tpl?' + __webpack_hash__,
            controller: 'TransactionController',
            controllerAs: 'transactionCtrl',
        })

        .when( '/:slug/transaction/:orderNumber/:orderTotal', {
            templateUrl: '/partials/pages/transaction.tpl?' + __webpack_hash__,
            controller: 'TransactionController',
            controllerAs: 'transactionCtrl',
        })

        .when('/:slug/success/:option/', {
            templateUrl: '/partials/success_payment_page.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when('/:slug/success/:option/:orderNumber', {
            templateUrl: '/partials/success_payment_page.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when('/:slug/success/:option/:orderNumber/:sum', {
            templateUrl: '/partials/success_payment_page.tpl?' + __webpack_hash__,
            controller: 'SuccessPaymentController',
            controllerAs: 'successPaymentCtrl'
        })

        .when( '/:slug/404', {
            templateUrl: '/partials/404.tpl?' + __webpack_hash__,
            controller: 'NotFoundController',
            controllerAs: 'notFoundCtrl'
        })

        .otherwise({
            templateUrl: '/partials/404.tpl?' + __webpack_hash__,
            controller: 'NotFoundController',
            controllerAs: 'notFoundCtrl'
        });

    NotificationProvider.setOptions({
        delay: 5000,
        startTop: 20,
        startRight: 10,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'center',
        positionY: 'bottom'
    });
})
.run( function ( $rootScope, AppService, AppConfigService, CityListService, CartService ) {
    "ngInject";

    var onceListener = $rootScope.$on('$routeChangeSuccess', function (event, current) {
        onceListener()

        AppService.initApp().then( function( result ) {

            if(typeof(current.params.slug ) !== 'undefined') {
                var selectedCity = AppConfigService.cityIsExist(current.params.slug);

                if( selectedCity !== 'undefined' ) {
                    AppService.initCity( selectedCity );
                }
            } else {
                CityListService.openCityListWindow();
            }
        });
    })
});

/* App Components */
require("./components");
require("./controllers");
require("./directives");
require("./services");

/* App Styles */
require('./../assets/css/styles.css');
