function MainMenuService ( $q, $http, CityConfigService )
{
    "ngInject";

    var options = {
        items: []
    };

    var init = function ()
    {
        if( CityConfigService.options.city == null )
        {
            return $q.reject( 'error' );
        } else {
            return $http.get( CityConfigService.options.city.base_url + "/api/handler.php?request=getMenu" );
        }
    }

    return {
        options: options,
        init: function ()
        {
            return init();
        }
    }
}

module.exports = MainMenuService;