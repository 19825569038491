var angular = require('angular')

angular.module('flowersApp').
  component('banners', require('./BannersComponent')).
  component('categoryListItem', require('./CategoryListItemComponent')).
  component('categoryItem', require('./CategoryItemComponent')).
  component('searchForm', require('./SearchFormComponent')).
  component('cartWidget', require('./CartWidgetComponent')).
  component('cartItem', require('./CartItemComponent')).
  component('randomCategoryLink', require('./RandomCategoryComponent')).
  component('kaomojiComponent', require('./KaomojiComponent')).
  component('additionalGoods', require('./AdditionalGoodsComponent')).
  component('purchaseComponent', require('./PurchaseComponent')).
  component('priceFilter', require('./PriceFilterComponent')).
  component('mobileHeader', require('./MobileHeaderComponent')).
  component('popupOnLeave', require('./PopupOnLeaveComponent')).
  component('reviewsItem', require('./ReviewsItemComponent')).
  component('freeDeliveryRegions', require('./FreeDeliveryRegionsComponent')).

  component('catalogFilter', require('./CatalogFilterComponent')).
  component("catalogFilterFieldBoolean", require('./CatalogFilterFieldBooleanComponent')).
  component("catalogFilterFieldRelation", require('./CatalogFilterFieldRelationComponent')).
  component("catalogFilterFieldPrice", require('./CatalogFilterFieldPriceComponent')).
  component("randomSeoLinks", require('./RandomSeoLinksComponent')).
  component("paymentMethods", require('./PaymentMethodsComponent'))
