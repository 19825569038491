function IndexController ( $rootScope, $location, $window, MetaService, StatusCodeService, CityConfigService ) {
    'ngInject';

    $rootScope.$on('$routeChangeStart', function (event, next, current) {
        if( !$window.ga ) {
            return;
        }

        $window.ga('send', 'pageview', {
            page: $location.path()
        });
    });

    var vm = this;
    vm.cityConfig = CityConfigService;

    vm.initIndex = function () {
        StatusCodeService.setStatusCode('200');

        MetaService.setMetaTags({
            title: "Главная",
            cityName: vm.cityConfig.options.city.name
        });
    };
}

module.exports = IndexController;