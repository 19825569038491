function CartPageController( CartService ) {
    "ngInject";

    var vm = this;
    vm.cartService = CartService;

    vm.getItems = function () {
        return CartService.data.items;
    }

    vm.updateItem = function ( item, quantity ) {
        console.log( 'function fired' );
    }
    
    vm.removeItem = function () {
        console.log( 'function 2 fired' );
    }
}

module.exports = CartPageController;