function CategoryListItemController ( AppConfigService, CityConfigService ){
    "ngInject";

    var vm = this;
        vm.cityConfig = CityConfigService;
        vm.appConfig = AppConfigService;
}

module.exports = {
    templateUrl: '/partials/components/CategoryListItemTemplate.tpl?' + __webpack_hash__,
    controller: CategoryListItemController,
    controllerAs: 'categoryListItemCtrl',
    bindings: {
        item: '<'
    }
}