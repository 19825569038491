function PriceFilterService ( $routeParams, $http, CityConfigService ) {
    "ngInject";

    var getCategoryRange = function ( categoryId ) {
        var categoryId = ( !categoryId ? parseInt( $routeParams.categoryId ) : parseInt( categoryId ) );
        if( typeof( categoryId ) != "number" ) {
            return;
        }

        return $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getCategoryRange',
                categoryId: categoryId
            }
        });
    }

    return {
        getCategoryRange: getCategoryRange
    }
}

module.exports = PriceFilterService;