function CityListWindowController (CityListService, AppConfigService, GeoLocationService, Notification) {
    "ngInject";

    let vm = this

    vm.appConfig = AppConfigService
    vm.cityGroupsList = {}
    vm.cityListForSearch = {}
    vm.searchCityName = ''
    vm.isInitialized = false
    vm.isCityDetectInProgress = false

    vm.clearSearchCityName = function () {
        vm.searchCityName = '';
    }

    vm.openCityListWindow = function () {
        CityListService.openCityListWindow();
    }

    vm.closeCityListWindow = function () {
        CityListService.closeCityListWindow();
    }

    vm.selectCity = function (city) {
        CityListService.selectCity(city);
    }

    vm.prepareCityGroupsList = function (cityListToPrepare) {
        var currentLetter = '';
        cityListToPrepare.forEach( function ( item ) {
            if (currentLetter !== item.name.charAt(0) && !vm.cityGroupsList[item.name.charAt(0)]) {
                currentLetter = item.name.charAt(0)
                vm.cityGroupsList[currentLetter] = {
                    letter: currentLetter,
                    items: []
                }
            }
            vm.cityGroupsList[currentLetter].items.push( item );
        });
    }

    vm.prepareCityList = function (cityListToPrepare) {
        vm.cityList = cityListToPrepare
    }

    vm.filterCity = function (item) {
        return (vm.searchCityName === '' || (item.name.toLowerCase()).startsWith(vm.searchCityName.toLowerCase()))
    }

    vm.getFilteredCityList = function () {
        return vm.cityList.filter(function (item) {
            return (vm.searchCityName === '' || (item.name.toLowerCase()).startsWith(vm.searchCityName.toLowerCase()))
        })
    }

    vm.detectCity = function () {
        vm.isCityDetectInProgress = true
        GeoLocationService.detectCity().then(function (result) {
            let foundedCity = vm.cityList.find(function (item) {
                return item.slug == result.data.slug
            })

            if (typeof foundedCity !== 'undefined') {
                Notification.success({message: 'Город успешно определен!'})
                vm.selectCity(foundedCity)
            } else {
                Notification.error({message: 'К сожалению, Вашего города нет в списке'})
            }
            vm.isCityDetectInProgress = false
        }).catch(function (error) {
            vm.isCityDetectInProgress = false

            let errorMessage = 'Неизвестная ошибка. Пожалуйста, повторите попытку позже'

            switch(error.code) {
                case 1: {
                    errorMessage = 'Доступ к геолокации запрещен настройками браузера'
                    break
                }
                case 2: {
                    errorMessage = 'Неудалось определить координаты'
                    break
                }
                case 3: {
                    errorMessage = 'Превышено время ожидания'
                }
            }

            console.log(error)

            Notification.error({message: errorMessage})
        })
    }

    vm.init = function () {
        if (!vm.isInitialized) {
            vm.prepareCityGroupsList(vm.appConfig.options.city.list)
            vm.prepareCityList(vm.appConfig.options.city.list)
            vm.isInitialized = true
        }
    }

    vm.init();
}

module.exports = CityListWindowController;