/* Vendor Libs */
require('angular');
require("angular-animate");
require("angular-route");
require("angular-global-resolve");
require("angular-cookies");
require("angular-ui-bootstrap");
require("angular-ui-notification");
require("ng-infinite-scroll");
require("angular-bootstrap-datetimepicker");
require("exif-js");
require("dropzone");
require("moment");
require("re-tree");
require("ua-device-detector");
require("angular-sanitize");
require("ng-device-detector");

require("bootstrap-slider");
require("angular-bootstrap-slider");

require("jquery");
require("@fancyapps/fancybox");
require("jquery-ui");
require("bootstrap");

require( "intersection-observer" );

require("angularjs-yandex-metrika");

require("./../lib/angular-locale_ru-ru.js");
//require("./../lib/angular-modules/angular-metrika.js");
require("./../lib/angular-modules/ng-device-detector.min.js");
require("./../lib/yandex-map.js");
require("./../lib/ui-bootstrap-tpls-2.0.0.min.js");
require("./../lib/angular-translate.min.js");
require("./../lib/slideout.min.js");
require("./../lib/mask.min.js");

/* Vendor Styles */
require('@fancyapps/fancybox/dist/jquery.fancybox.min.css');
require('bootstrap/dist/css/bootstrap.min.css');
require('dropzone/dist/dropzone.css');
require('angular-ui-notification/dist/angular-ui-notification.min.css');