function BannersController ( BannersService, CityConfigService )
{
    'ngInject';

    var vm = this;

    vm.options = BannersService.options;
    vm.cityConfig = CityConfigService;
    vm.slidesInterval = 3000;

    vm.$onInit = function ()
    {
        if( vm.options.items.length == 0 ){
            BannersService.loadBanners();
        }
    }
}

module.exports = {
    templateUrl: '/partials/components/BannersTemplate.tpl?' + __webpack_hash__,
    controller: BannersController,
    controllerAs: 'bannersCtrl'
}