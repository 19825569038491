/*
     Контроллер для элемента каталога
*/

function CatalogItemListService ( $interpolate, $q, $http, $routeParams, $rootScope, $location, $route, MetaService, StatusCodeService, CityConfigService, PriceFilterService ) {

    "ngInject";

    var catalog = {
        id: undefined,
        currentPage: 0,
        isEndPage: true,
        isLoading: false,
        catalogName: "",
        totalPagesCount: 0,
        price: {
            min: null,
            max: null
        },
        seoContent: '',
        items: []
    };

    var setMetaData = false;
    var route = $routeParams;

    /*
        Метод для очистки переменной каталога
     */
    var clearCatalogData = function()
    {
        var deffered = $q.defer();

        catalog.id = undefined;
        catalog.currentPage = 0;
        catalog.isEndPage = true;
        catalog.isLoading = false;
        catalog.catalogName = "";
        catalog.totalPagesCount = 0;
        catalog.price.min = 0;
        catalog.price.max = 0;
        catalog.items = [];
        catalog.seoContent = ''

        deffered.resolve( catalog );

        return deffered.promise;
    }

    /*
        Метод для "ленивой" подгрузки элементов каталога
     */
    var lazyLoadItems = function () {
        if ( catalog.isLoading || catalog.isEndPage || !catalog.id) {
            return;
        }

        var requestParams = {
            'request': 'getCatalogItemsById',
            'categoryId': catalog.id,
            'currentPage': catalog.currentPage,
            'limitPerPage': 8,
        }

        Object.assign(requestParams, $location.search())

        catalog.isLoading = true;

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: 'GET',
            params: requestParams,
            cache: true
        }).then(function (response) {
            recievedItems = response.data.data.items;
            catalog.isEndPage = response.data.data.isEndPage;

            if( !catalog.isEndPage ) {
                catalog.currentPage++;
            }

            Array.prototype.push.apply(catalog.items, recievedItems);

            catalog.isLoading = false;
        }).catch( angular.noop );
    }

    /*
        Старый метод для загрузки всех элементов каталога
     */
    var loadCatalogItems = function () {
        catalog.items = [];
        catalog.currentPage = 0;

        requestParams = {
            'request': 'getCatalogItemsById',
            'categoryId': catalog.id,
            'currentPage': catalog.currentPage,
            'limitPerPage': 8
        };

        Object.assign(requestParams, $location.search())

        catalog.isLoading = true;

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: 'GET',
            params: requestParams,
            cache: true
        }).then( function ( response ) {
            StatusCodeService.setStatusCode( '200' );

            catalog.items = response.data.data.items
            catalog.isEndPage = response.data.data.isEndPage
            catalog.totalPagesCount = response.data.data.totalPagesCount
            catalog.seoContent = (typeof response.data.data.seoContent !== 'undefined') ? response.data.data.seoContent : ''

            catalog.catalogName = undefined
            if (typeof response.data.data.catalogName !== 'undefined') {
                let catalogNameInterpolate = $interpolate(response.data.data.catalogName)
                catalog.catalogName = catalogNameInterpolate(CityConfigService.options.config)
            }


            if (!catalog.isEndPage) {
                catalog.currentPage++;
            }

            catalog.isLoading = false;

            if (setMetaData === true) {
                let title = (typeof response.data.data.seoTitle === 'undefined') ? catalog.catalogName : response.data.data.seoTitle,
                    descriptionTemplate = (typeof response.data.data.seoMetaDescription === 'undefined') ? '' : response.data.data.seoMetaDescription,
                    keywordsTemplate = (typeof response.data.data.seoMetaKeywords === 'undefined') ? '' : response.data.data.seoMetaKeywords

                setMetaDataForCategory(title, CityConfigService.options.city.name, descriptionTemplate, keywordsTemplate)
            }

        }).catch( function (e) {
            //console.log(e)
            $location.url( '/' + CityConfigService.options.city.slug + '/404' );
        });
    };

    /*
        Метод для инициализации каталога
     */
    var initCatalogList = function (catalogId, updateMetaData) {
        setMetaData = (typeof(updateMetaData) !== "boolean" || updateMetaData === true) ? true : false;
        catalog.id = !catalogId ? $routeParams.categoryId : catalogId;
        catalog.items = null;
        loadCatalogItems();
    };

    /*
        Метод для задания метатегов для категории
     */
    var setMetaDataForCategory = function (title, cityName, descriptionTemplate, keywordsTemplate) {
        MetaService.setMetaTags({
            title: (typeof title === 'undefined' ? 'Акции' : title),
            cityName: cityName,
            descriptionTemplate: descriptionTemplate,
            keywordsTemplate: keywordsTemplate,
        });
    }

    return {
        options: catalog,
        initCatalogList: initCatalogList,
        loadCatalogItems: loadCatalogItems,
        lazyLoadItems: lazyLoadItems,
        clearCatalogData: clearCatalogData,
    }
}

module.exports = CatalogItemListService;