function NotFoundService ( $rootScope, StatusCodeService, MetaService, CityConfigService )
{
    "ngInject";
    var mainPage = {
        link: "/"
    };

    var init = function ()
    {
        StatusCodeService.setStatusCode('404');

        if( CityConfigService.options.city != null )
        {
            mainPage.link = "/" + CityConfigService.options.city.slug
        }

        MetaService.setMetaTags({
            title: "Страница не найдена | Цветули - Доставка цветов в г. " + CityConfigService.options.config.city,
        });

        $rootScope.title = "Страница не найдена | Цветули - Доставка цветов в г. " + CityConfigService.options.config.city;
    }

    return {
        mainPage: mainPage,
        init: init
    }
}

module.exports = NotFoundService;