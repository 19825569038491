function CartController ( CartService, CityConfigService, MetaService )
{
    "ngInject";

    var vm = this;
    vm.cartService = CartService;


    vm.getItems = function () {
        return CartService.data.items.length === 0 ? [] : CartService.data.items;
    }

    vm.appendToCart = function ( item, quantity ) {
        CartService.appendToCart( item, quantity );
    }

    vm.updateItem = function ( item, quantity ) {
        CartService.appendToCart( item, quantity );
    }

    vm.removeItem = function ( item ) {
        CartService.removeFromCart( item );
    }

    vm.clearCart = function () {
        CartService.clearCart();
    }

    vm.isItemInCart = function ( item ) {
        if( typeof( item.id ) !== 'undefined' ) {
            return CartService.isItemInCart( item );
        }
    }

    vm.setMetaData = function () {
        MetaService.setMetaTags({
            title: 'Корзина',
            cityName: CityConfigService.options.city.name
        });
    }
}

module.exports = CartController;