function MainMenuController ( MainMenuService, AppConfigService )
{
    "ngInject";

    var vm = this;
    vm.options = MainMenuService.options;

    vm.filterPages = function ( element )
    {
        return !AppConfigService.options.excludedPages.includes( element.link );
    }
}

module.exports = MainMenuController;