function PurchaseService ( $window, $q, $location, $cookies, $http, CityConfigService, CatalogItemService, AppConfigService, CartService, HelpersService, EcommerceService, PromoCodeService )
{
    "ngInject";

    var options = {
        schedule: {},
        exceptions: [],
        timePeriods: [],
        deliveryRegions: [],
        deliveryTypes: [],
        paymentMethods: [],
        formSubmitting: false,
        loading: {
            price: false,
            forming: false,
            deliveryRegions: false,
            timePicker: false
        },
        lastSeenItems: [],
        dateTimePickerConfig: {
            startView: 'day',
            minView: 'day'
        },
        processing: false
    };

    var orderData = {
        selectedDelivery: {},
        is_anonymous: false,
        delivery_customer_name: "",
        delivery_client_name: "",
        customer_phone_number: "",
        phone_number: "",
        delivery_region: null,
        delivery_address: "",
        date: null,
        time: null,
        promo_code: null,
        promo_error: null,
        promo_type: null,
        promo_sum: null,
        payment_id: null,
        parent_payment_id: null,
        cardText: "",
        noNeedText: false,
        order_comment: "",
        needPhoto: false,
        network: ''
    };

    var termsOfUse = {
        isCheckTerms: true
    };

    var orderSum = {
        discountId: null,
        promoSum: 0
    };

    var getDateTimePickerConfig = function ()
    {
        return options.dateTimePickerConfig;
    }

    /**
     * Метод для очистки данных объекта заказа
     * @returns {Q.Promise<T>}
     */
    var clearOrderData = function ()
    {
        deferred = $q.defer();

        orderData = {
            selectedDelivery: {},
            is_anonymous: false,
            delivery_customer_name: "",
            delivery_client_name: "",
            customer_phone_number: "",
            phone_number: "",
            delivery_region: null,
            delivery_address: "",
            date: null,
            time: null,
            promo_code: null,
            promo_error: null,
            promo_type: null,
            promo_sum: null,
            payment_id: null,
            parent_payment_id: null,
            cardText: "",
            noNeedText: false,
            order_comment: "",
            needPhoto: false,
            network: ''
        };

        deferred.resolve( true );

        return deferred.promise;
    }

    /**
     * Метод для получения списка товаров в корзине
     * @returns {array}
     */
    var getOrderItems = function () {
        return CartService.data.items;
    }

    /**
     * Метод для обновления стоимости заказа
     * @returns {boolean}
     */
    var updateOrderPrice = function () {
        if ( options.loading.catalogItem || ( typeof ( orderData.selectedDelivery ) == 'undefined' ) || options.loading.price === true ) {
            return false;
        }

        options.loading.price = true;

        items = getOrderItems();

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "POST",
            data: {
                request: 'getOrderPrice',
                items: items,
                deliveryId: orderData.selectedDelivery.id,
                deliveryRegion: ( typeof( orderData.delivery_region ) !== undefined ? orderData.delivery_region.id : null ),
                promoCode: orderData.promo_code,
                needPhoto: orderData.selectedDelivery.selfdelivery === true ? false : orderData.needPhoto
            },
            cache: false
        }).then( function ( response ) {
            options.loading.price = false;
            orderSum = Object.assign( orderSum, response.data.data );
        })
          .catch( angular.noop );
    };

    var getDeliveryTypes = function ()
    {
        options.loading.deliveryTypes = true;
        return $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getDeliveryTypes'
            },
            cache: false
        }).then(function (response)
        {
            options.loading.deliveryTypes = false;
            options.deliveryTypes = response.data.data;

            if ( options.deliveryTypes.length > 0 )
            {
                orderData.selectedDelivery = options.deliveryTypes[1];
            }

        }).catch( angular.noop );
    };

    var getDeliveryRegions = function ()
    {
        options.loading.deliveryRegions = true;
        return $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getDeliveryRegions'
            },
            cache: false
        }).then(function (response) {
            options.loading.deliveryRegions = false;
            options.deliveryRegions = response.data.data;
            orderData.delivery_region = options.deliveryRegions[0].id;
        }).catch( angular.noop );
    };

    /**
     * Метод запрашивающий время работы точки самовывоза
     */
    var getWorkingHours = function () {
        var selfDeliveryPointId = ( typeof( orderData.selectedDelivery.selfdelivery_point ) == "undefined" ) ? "" : orderData.selectedDelivery.selfdelivery_point.id;

        options.loading.timePicker = true;
        return $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getWorkhours',
                selfdeliveryPoint: selfDeliveryPointId
            },
            cache: false
        }).then( function ( response ) {
            var data = response.data.data;

            options.schedule = ( data.schedule === '' ) ? '' : data.schedule;
            options.exceptions = ( typeof( data.exceptions ) != 'object' ) ? [] : data.exceptions;

            options.loading.timePicker = false;
        }).catch( angular.noop );
    };

    /**
     * Метод для подготовки списка интервалов работы точки самовывоза
     *
     * @param date
     */
    var prepareWorkingHours = function ( date )
    {
        options.timePeriods = [];

        options.timePeriods.push('');

        date = new Date(date);
        day = date.getDate();
        month = date.getMonth();
        dayNum = date.getDay();

        var dateObject = new Date();
        var nowDate = {
            dayNum: dateObject.getDay(),
            day: dateObject.getDate(),
            month: dateObject.getMonth(),
            year: dateObject.getFullYear()
        };

        var isToday = false;
        if ( nowDate.day == day )
        {
            isToday = true;
        }

        var dateObject = new Date();
        var timeMatrix = [];

        var schedule = HelpersService.explode( ";", options.schedule );

        var start_hour = 0;
        var end_hour = 0;
        var interval = 0;

        switch( dayNum )
        {
            case 0:
            {
                start_hour = schedule[7];
                end_hour = schedule[8];
                interval = schedule[9];
            }

            case 6:
            {
                start_hour = schedule[4];
                end_hour = schedule[5];
                interval = schedule[6];

                break;
            }

            default:
            {
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }

        }

        options.exceptions.forEach( function ( item, index, sourceArray ) {
            var exc_day = item.date.substr( 0, 2 );
            var exc_month = item.date.substr( 3, 2 );

            if ( parseInt( exc_day ) == day && parseInt( exc_month ) == month + 1 )
            {
                var schedule = HelpersService.explode( ";", item.exc_schedule );
                start_hour = schedule[1];
                end_hour = schedule[2];
                interval = schedule[3];
            }
        });

        var build_time = schedule[0];

        var m = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( start_hour.substr( 0, 2 ) ), parseInt( start_hour.substr( 3, 2 ) ) ) );
        var mEnd = moment( new Date( date.getFullYear(), date.getMonth(), date.getDate(), parseInt( end_hour.substr( 0, 2 ) ), parseInt( end_hour.substr( 3, 2 ) ) ) );
        var now = moment( CityConfigService.options.config.server_time );

        var i = 0;

        while ( ( m.unix() + interval * 60 ) <= mEnd.unix() ) {

            hours = ( m.hours() < 10 ) ? "0" + m.hours() : m.hours();
            minutes = ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes();
            m.add( interval, 'minutes' );

            calculatedValue = now.unix() + build_time * 60;

            if ( m.unix() >= calculatedValue )
            {
                time = hours + ":" + minutes + " - " + ( ( m.hours() < 10 ) ? "0" + m.hours() : m.hours() ) + ":" + ( ( m.minutes() < 10 ) ? "0" + m.minutes() : m.minutes() );
                if ( i == 0 )
                {
                    orderData.time = time;
                }

                options.timePeriods.push( time );
                i++;
            }
        }
        orderData.time = options.timePeriods[0];
    };

    var beforeCalendarRender = function ( $viewType, $datePickerDates, $leftDate, $upDate, $rightDate )
    {
        switch ( $viewType )
        {
            case 'year':
                {
                    renderYearView ( $datePickerDates );
                    break;
                };

            case 'month':
                {
                    renderMonthView ( $datePickerDates );
                    break;
                }

            case 'day':
                {
                    renderDayView ( $datePickerDates );
                    break;
                }
        }
    };

    var renderYearView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        $datePickerDates.forEach( function ( item, index, sourceArray ) {
            var datePickerDate = new Date( item.utcDateValue );
            if ( currentDate.getYear() > datePickerDate.getYear() ) {
                item.selectable = false;
            }
        });
    };

    var renderMonthView = function ( $datePickerDates )
    {
        var currentDate = new Date();

        $datePickerDates.forEach( function ( item, index, sourceArray ) {
            var datePickerDate = new Date( item.utcDateValue );
            var isSelectable = true;

            if ( currentDate.getFullYear() > datePickerDate.getFullYear() ) {
                isSelectable = false;
            }

            if ( currentDate.getFullYear() == datePickerDate.getFullYear() && currentDate.getMonth() > datePickerDate.getMonth() ) {
                isSelectable = false;
            }

            item.selectable = isSelectable;
        });
    };

    var renderDayView = function ( $datePickerDates )
    {
        var currentDate = new Date();
        currentDate.setHours( 0, 0, 0, 0 );

        $datePickerDates.forEach( function ( item, index, sourceArray ) {
            var datePickerDate = new Date( item.utcDateValue );
            datePickerDate.setHours( 0, 0, 0, 0 );

            if ( currentDate > datePickerDate )
            {
                item.selectable = false;
            }
        });
    };

    var initPromoCode = function () {
        orderData.promo_code = PromoCodeService.get();
    }

    var checkPromoCode = function ()
    {

        if( orderData.promo_code == undefined || orderData.promo_code == null )
        {
            updateOrderPrice()
            return $q.when({})
        }

        return PromoCodeService.check(orderData.promo_code).
          then(function (data) {
              var response = data.data

              if (response.data.error != false) {
                  orderData.promo_error = response.data.msg
              } else {
                  orderData.promo_error = false
                  orderData.promo_sum = response.data.sum
                  orderData.promo_type = response.data.type

                  PromoCodeService.set(orderData.promo_code)
              }
              updateOrderPrice();
          })
    };

    var rejectPromoCode = function ()
    {
        PromoCodeService.remove()

        orderData.promo_code = ''
        orderData.promo_error = false
        orderData.promo_sum = 0
        orderData.promo_type = 0

        updateOrderPrice()
    };

    /**
     * Метод возвращает список доступных методов оплаты
     * @returns {boolean}
     */
    var getAvailablePayments = function () {
        if ( typeof( orderData.selectedDelivery ) == 'undefined' )
        {
            return false;
        }

        orderData.payment_id = null
        orderData.parent_payment_id = null

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "GET",
            params: {
                request: 'getPaymentMethodsForWebByDeliveryId',
                deliveryId: orderData.selectedDelivery.id
            },
            cache: false
        }).then( function ( response )
        {
            options.paymentMethods = preparePaymentsList(response.data.data);
        });
    }

    /**
     * Метод для подготовки списка методов оплаты
     * @param paymentsList
     * @param parentId
     * @returns {Array}
     */
    var preparePaymentsList = function (paymentsList, parentId) {
        var preparedList = []
        parentId = typeof(parentId) !== "undefined" ? parentId : null
        if (paymentsList.length > 0) {
            paymentsList.forEach(function(item, index, array) {
                if (typeof(item.payment_subtypes) !== "undefined" && item.payment_subtypes.length > 0) {
                    preparedList = preparedList.concat(preparePaymentsList(item.payment_subtypes, item.id))
                } else {
                    item = Object.assign(item, {parentId: parentId})
                    preparedList.push(item)
                }
            });
        }

        return preparedList
    }

    var doPurchase = function ()
    {
        var items = getOrderItems();
        var sendingData = Object.assign( {}, orderData );

        options.loading.forming = true;
        sendingData.network = AppConfigService.options.network;

        var date = new Date( sendingData.date );
        sendingData.date_formatted = date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();

        options.formSubmitting = true;

        sendingData.delivery_id = sendingData.selectedDelivery.id;
        if ( sendingData.selectedDelivery.selfdelivery )
        {
            sendingData.selfdelivery_point = sendingData.selectedDelivery.selfdelivery_point;
        }

        if( typeof( sendingData.delivery_region ) !== 'undefined' )
        {
            //sendingData.delivery_region = sendingData.delivery_region.id;
            sendingData.delivery_region = sendingData.delivery_region;
        }

        if ( sendingData.noNeedText === true )
        {
            sendingData.cardText = "";
        } else {
            items.forEach( function ( item, index, sourceArray ) {
                if( item.type == 'card' ) {
                    if( typeof( item.cardText ) == 'object' ) {
                        for( var i = 0; i < item.cardText.length; i++ ) {
                            sendingData.cardText += ( item.name + ' №' + ( i + 1 ) + ': ' + item.cardText[i].content + '; ' );
                        }
                    } else {
                        sendingData.cardText += ( item.name + ': ' + item.cardText + '; ' );
                    }
                }
            });
        }

        $http({
            url: CityConfigService.options.city.base_url + "/api/handler.php",
            method: "POST",
            data: {
                request: 'formOrder',
                data: sendingData,
                items: items
            },
            cache: false
        }).then( function ( response )
        {
            var redirect = response.data.data.redirect;

            var transactionObject = {};
            transactionObject.orderNumber = response.data.data.order_number;
            transactionObject.orderSum = Object.assign( {}, orderSum );
            transactionObject.orderItems = Object.assign( [], items );

            if ( redirect.indexOf( '://' ) == -1 ) {
                EcommerceService.makeEcommerceTransaction( transactionObject, 0 ).then(function(){
                  urlArr = redirect.split("/");
                  urlArr.splice(0, 1);
                  $location.url(
                    "/" + CityConfigService.options.city.slug + "/" +
                    urlArr.join("/"));
                });
            } else {
                $location.url( "/" + CityConfigService.options.city.slug + "/transaction/" + transactionObject.orderNumber + "/" + orderSum.withDiscount  );
                EcommerceService.makeEcommerceTransaction( transactionObject, 2000 ).then( function () {
                    $window.location.href = redirect;
                });
            }

            options.formSubmitting = false;
            CartService.clearCart();
            PromoCodeService.remove()
        });
    }

    return {
        clearOrderData: clearOrderData,

        options: function ()
        {
            return options;
        },
        orderData: function ()
        {
            return orderData;
        },
        orderSum: function ()
        {
            return orderSum;
        },

        getDateTimePickerConfig: getDateTimePickerConfig,

        updateOrderPrice: updateOrderPrice,
        getDeliveryTypes: getDeliveryTypes,
        getDeliveryRegions: getDeliveryRegions,

        getWorkingHours: getWorkingHours,
        prepareWorkingHours: prepareWorkingHours,
        beforeCalendarRender: beforeCalendarRender,

        initPromoCode: initPromoCode,
        checkPromoCode: checkPromoCode,
        rejectPromoCode: rejectPromoCode,

        getOrderItems: getOrderItems,
        getAvailablePayments: getAvailablePayments,

        doPurchase: doPurchase
    }
}

module.exports = PurchaseService;