function AppController ( $window, $location, $timeout, $rootScope, $routeParams, $metrika, AppService, AppConfigService, CityConfigService, MetaService, CityListService )
{

    'ngInject';
    var vm = this;

    var path = $location.path();

    vm.appConfig = AppConfigService;
    vm.cityConfig = CityConfigService;
    vm.categoriesList = [];

    $rootScope.$on( '$routeChangeStart', function( event, next, current )
    {
        var newPath = $location.path();

        if ( $window.ga )
        {
            $window.ga( 'send', 'pageview', { page: newPath } );
        }

        $metrika
            .counterIsLoaded()
            .then( function (counter)
            {
                return counter.hit(newPath, {
                    referer: path
                });
            });

        path = newPath;
    });

    $rootScope.$on( "$routeChangeSuccess", function( event, current, previous, rejection )
    {
        var needScrollToTop = true;

        if( ( previous !== undefined  && current !== undefined) && ( previous.params.itemId !== undefined && current.params.categoryId !== undefined ) )
        {
            needScrollToTop = false;
        }

        if ( needScrollToTop )
        {
            $window.scrollTo( 0, 0 );
        }

    });
}

module.exports = AppController;