function SuccessPaymentService ( $http, $routeParams, $location, $route, StatusCodeService, CityConfigService, HelpersService )
{
    "ngInject";

    var values = {};

    var init = function ()
    {
        values.orderNumber = null;
        values.orderSum = null;
        values.option = $routeParams.option;

        uriGetParams = $location.search();
        StatusCodeService.setStatusCode('200');

        if ( typeof($routeParams.sum ) != "undefined" )
        {
            values.orderSum = $routeParams.sum;
        }

        if ( typeof( $routeParams.orderNumber ) != "undefined" )
        {
            values.orderNumber = $routeParams.orderNumber;
        }
        else
        {
            if ( typeof( uriGetParams.order_id ) !== "undefined" )
            {
                $http({
                    url: CityConfigService.options.city.base_url + "/api/handler.php",
                    method: "GET",
                    params: {
                        request: 'getOrderNumber',
                        orderObjectId: uriGetParams.order_id
                    }
                }).then( function ( response )
                {
                    if ( response.data )
                    {
                        values.orderNumber = response.data.orderNumber;
                    }
                });
            }
        }
    }

    return {
        values: values,
        init: init
    }
}

module.exports = SuccessPaymentService;