function CartItemController( $scope, $timeout, CityConfigService ) {
    "ngInject";

    var vm = this;
    vm.quantityTimer = null;
    vm.cityConfig = CityConfigService;

    vm.$onInit = function () {
        if( vm.item.type === 'card' ) {
            vm.textEditTimer = null;
        }

        if( vm.item.type === 'card' && typeof( vm.item.noNeedText ) == 'undefined' ) {
            vm.item.noNeedText = false;
        }

        if( vm.item.type === 'card' && typeof( vm.item.cardText ) == 'undefined' ) {
            vm.item.cardText = "";
        }
    }

    vm.onCardTextChangeEvent = function () {

        $scope.$emit( 'only-save-cart' );
/*        vm.textEditTimer = $timeout( function () {
            if( vm.item.cardText !== '' ) {
                vm.setItemQuantity( vm.item.quantity );
            }
        }, 1000);*/
    }

    vm.onCardTextEnabledChangeEvent = function () {
        vm.setItemQuantity( vm.item.quantity );
    }

    vm.isObject = function ( value ) {
        return typeof( value ) == 'object';
    }

    vm.isString = function ( value ) {
        return typeof( value ) == 'string';
    }

    vm.changeCardTextType = function ( quantity ) {
        if( ( vm.item.quantity == 1 ) && ( quantity > 1 ) ) {
            vm.item.noNeedText = false;
            var previousContent = vm.item.cardText;
            vm.item.cardText = [{
                content: previousContent
            }];
        } else if( ( vm.item.quantity > 1 ) && ( quantity == 1 ) ) {
            var previousContent = vm.item.cardText[0].content;
            vm.item.cardText = previousContent;
        }

        if( ( ( vm.item.quantity == 1 ) || ( vm.item.quantity > 1 ) ) && ( quantity > vm.item.quantity ) ) {
            for( var i = vm.item.cardText.length; i < quantity; i++ ) {
                vm.item.cardText.splice( vm.item.cardText.length, 0, { content: "" } );
            }
        }

        if( ( typeof( vm.item.cardText ) == 'object' ) && ( vm.item.quantity > 1 ) && ( quantity < vm.item.quantity ) ) {
            for( var i = vm.item.cardText.length; i >= quantity ; i-- ) {
                vm.item.cardText.splice( i, 1 );
            }
        }
    }

    vm.setItemQuantity = function ( quantity ) {

        if( vm.item.type == 'card' ) {
            vm.changeCardTextType( quantity );
        }

        vm.onUpdate({ item: vm.item, quantity: quantity });
    }

    vm.removeItem = function () {
        vm.onDelete({ item: vm.item });
    }

    vm.onInputQuantity = function () {
        $timeout.cancel( vm.quantityTimer );
        vm.quantityTimer = $timeout( function () {
            if( vm.item.quantity > 0 ) {
                vm.setItemQuantity( parseInt(vm.item.quantity) );
            }
        }, 300);
    }
}

module.exports =  {
    templateUrl: '/partials/components/CartItemTemplate.tpl?' + __webpack_hash__,
    bindings: {
        item: '<',
        onDelete: '&',
        onUpdate: '&',
    },
    controller: CartItemController,
    controllerAs: 'cartItemCtrl',
};