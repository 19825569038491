function AppService( $rootScope, AppConfigService, CityConfigService, CategoriesService, MetaService, MainMenuService )
{
    "ngInject";

    var initApp = function ()
    {
        return AppConfigService.options.isInit
            ? true
            : AppConfigService.init().then( function( res ) {
                MetaService.setMetaTags({
                    title: "Главная",
                    img: "/assets/images/full_logo.png"
                });
            });
    }

    var initCity = function ( selectedCity )
    {
        CityConfigService.init( selectedCity );

        CategoriesService.getList().then(
            function ( items )
            {
                CityConfigService.options.categories = items;
                return true;
            }
        );

        MainMenuService.init().then(
            function( response )
            {
                MainMenuService.options.items = response.data.data;
            }
        );
    }

    return {
        initApp: initApp,
        initCity: initCity
    }
}

module.exports = AppService;
