function lazyLoadDirectiveController () {
  var vm = this,
    observer = null,
    img = null;

  vm.init = function ( element ) {
    observer = new IntersectionObserver(checkChanges);
    img = angular.element(element)[0];

    installPreloaderImage(img);

    observer.observe(img);
  }

  function installPreloaderImage ( element ) {
    element.setAttribute( 'src', element.getAttribute( 'lazy-placeholder-src' ) );
    element.removeAttribute( 'lazy-placeholder-src' );
  }

  function onImageLoad ( event ) {
    event.target.removeAttribute( 'lazy-load' );
  }

  function checkChanges ( changes ) {
    changes.forEach( function( change ) {
      if (change.intersectionRatio > 0) {
        removeObserve( change.target );
        loadImage( change.target );
        change.target.addEventListener('load', onImageLoad);
      }
    });
  }

  function removeObserve( element ) {
    observer.unobserve( element );
  }

  function loadImage ( element ) {
    element.setAttribute( 'src', element.getAttribute('lazy-src') );
    element.removeAttribute( 'lazy-src' );
  }
}

function LazyLoadDirective () {
  return {
    restrict: 'A',
    controllerAs: 'lazyLoadCtrl',
    controller: lazyLoadDirectiveController,
    link: function (scope, element, attrs) {
      scope.lazyLoadCtrl.init( element );
    }
  }
}

module.exports = LazyLoadDirective;