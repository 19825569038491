function AppConfigService ( $window, $http, $location, $metrika )
{
    "ngInject";

    var options = {
            isInit: false,
            city: {
                list: [],
                selected: null
            },
            metrics: {},
            appLinks: {},
            seo: {},
            excludedPages: [],
            excludedPayments: [],
            copyright: '',
            network: '',
            verify: [],
            webdriver: $window.navigator.webdriver == true
        };

    var checkCityIsExist = function ( slug )
    {
        elementsCount = options.city.list.length;

        for( elementIndex = 0; elementIndex < elementsCount ; elementIndex++ )
        {
            element = options.city.list[elementIndex];
            if ( element.slug == slug )
            {
                return element;
            }
        }

        return false;
    }

    var initializeSiteMetrics = function ()
    {
        if ( options.metrics.yandexMetrika != '' )
        {
            $metrika.counterConfigs.push({
                id: options.metrics.yandexMetrika,
                clickmap: true,
                trackLinks: true,
                accurateTrackBounce: true,
                webvisor: true,
                trackHash: true,
                ecommerce: "dataLayer"
            });
            $metrika.defaultCounterId = options.metrics.yandexMetrika;
            $metrika.insertMetrika();

            $window.dataLayer = $window.ecommerceLayer || [];
        }

        if ( options.metrics.googleAnalytics != '' )
        {
            $window.ga( 'create', options.metrics.googleAnalytics, 'auto' );
            $window.ga( 'require', 'ecommerce' );
            $window.ga( 'send', 'pageview', { page: $location.path() } );
        }
    }

    var init = function ()
    {
        return $http
            .get('/api/config').then( function ( result ) {
                let response = result.data;

                options.city.list = response.citiesList.data;
                options.metrics = response.metrics;
                options.appLinks = response.appLinks;
                options.seo = response.seo;

                options.appTitle = response.appTitle;
                options.infoPaymentsList = response.infoPaymentsList;

                options.excludedPages = response.excludedPages;
                options.excludedPayments = response.excludedPayments;

                options.copyright = response.copyright;
                options.reviewsLinkActive = response.reviewsLinkActive;
                options.network = response.network;

                options.verify = response.verify;

                initializeSiteMetrics();

                if( $location.host() == "cvetuly-promo.yugs.ru" ) {
                    options.city.list.push({
                        id: 1,
                        base_url: "//buket-cvetuly.yugs.ru",
                        name: "Тестовый магазин",
                        slug: "promo",
                        shop: "Тестовый магазин"
                    });
                }
                options.isInit = true;
            })
            .catch( angular.noop );

    }

    return {
        options: options,
        init: init,
        cityIsExist: checkCityIsExist
    }
}

module.exports = AppConfigService;