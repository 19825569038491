function ContentService ( $sce, $rootScope, $location, $routeParams, $http, MetaService, StatusCodeService, CityConfigService )
{
    "ngInject";

    var page = undefined;

  /**
   * Метод для запроса контента страницы
   */
  var getContent = function ( pageName ) {
    var requestParams = {
      request: "getContentPage",
      page: pageName
    };

    return $http({
      url: CityConfigService.options.city.base_url + "/api/handler.php",
      method: "GET",
      params: requestParams
    })
    .then( function ( response ) {
      page = response.data.data;
      page.urlName = pageName;
      StatusCodeService.setStatusCode('200');

      page.content = $sce.trustAsHtml(page.content)

      MetaService.setMetaTags({
        title: page.name,
        cityName: CityConfigService.options.city.name
      });

      return page;
    })
    .catch( function () {
      $location.url( "/" + CityConfigService.options.city.slug + "/404" );
    });
  }

  return {
      getContent: getContent
  }

}

module.exports = ContentService;