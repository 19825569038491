function PopupLeaveController ( $timeout, $cookies, $scope, $rootScope, $window, CityConfigService ) {
  "ngInject";

  var vm = this;

  vm.popupShowed = false;

  vm.popupTextContent = null;
  vm.popupImage = null;

  vm.popupEnabled = false;

  vm.writeCookie = function () {
    var expireDate = new Date();
    expireDate.setDate( expireDate.getDate() + 180 );

    $cookies.put( CityConfigService.getSlug() + '-popup-on-leave-disabled', true, {
      'expires': expireDate
    })
  }

  vm.removeEventListener = function () {
    $window.document.removeEventListener( "mouseleave", vm.showPopup );
  }

  vm.addEventListener = function () {
    $window.document.addEventListener( "mouseleave", vm.showPopup );
  }

  vm.disablePopup = function () {
    vm.popupEnabled = false;

    vm.writeCookie();
    vm.removeEventListener();
  }

  vm.enablePopup = function () {
    vm.addEventListener();
  }

  vm.isPopupEnabled = function () {
    if( typeof( CityConfigService.options.config.banner_on_close ) != 'undefined' ) {
      return !$cookies.get( CityConfigService.getSlug() + '-popup-on-leave-disabled' ) && CityConfigService.options.config.banner_on_close.off !== 1;
    }

    return false;
  }

  vm.showPopup = function () {
    vm.disablePopup();
    $scope.$apply( vm.popupShowed = !vm.popupShowed );
  }

  vm.closePopup = function () {
    vm.popupShowed = !vm.popupShowed;
  }

  vm.initializePopup = function () {
    vm.popupEnabled = vm.isPopupEnabled();

    vm.popupShowed = false;

    if( vm.popupEnabled ) {
      vm.popupContent = CityConfigService.options.config.banner_on_close.content;
      vm.popupImage = CityConfigService.options.city.base_url + CityConfigService.options.config.banner_on_close.url_img;

      vm.addEventListener();
    } else {
      vm.removeEventListener();
    }
  }

  $rootScope.$on( "after-city-change", function () {
    vm.initializePopup();
  });
}

function template () {
  return `
    <div class="modal-popup-container" ng-if="popupLeaveCtrl.popupShowed === true">
      <div class="modal-popup-background" ng-click="popupLeaveCtrl.closePopup()">
        <div class="modal-popup-content">
           <div class="container-fluid">
           <div class="row">
             <div class="col-xs-12">
              <div class="panel">
                <div class="panel-body">                
                    <div class="row">
                        <div class="col-xs-6">
                            <img ng-src="{{ popupLeaveCtrl.popupImage }}" />
                        </div>
                        <div class="col-xs-6" ng-bind-html="popupLeaveCtrl.popupContent">
                        </div>
                    </div>
                    
                    <a class="close-popup" href="#" ng-click="popupLeaveCtrl.closePopup()">
                        <span aria-hidden="true">×</span>
                    </a>
                </div>
              </div>
              </div>
            </div>
           </div>
        </div>
    </div>
`;
}

module.exports = {
  template: template(),
  controller: PopupLeaveController,
  controllerAs: 'popupLeaveCtrl'
}