function CategoriesService ( $rootScope, $http, $q, CityConfigService )
{
    "ngInject";

    var items = undefined;

    var getList = function ()
    {
        var deffered = $q.defer();

        if( CityConfigService.options.city == null )
        {
            deffered.reject( 'error' );
        } else {
            $http
                .get( CityConfigService.options.city.base_url + "/api/handler.php?request=getCategories" )
                .then( function ( result ) {
                    items = result.data.data.filter( function( item ) {
                        return item.link !== 'collect';
                    });

                    $rootScope.$emit( 'categories-list-loaded' );

                    deffered.resolve( items );
                }, function ( error ) {
                    items = undefined;
                    deffered.reject( error );
                })
              .catch( angular.noop );
        }

        return deffered.promise;
    }

    return {
        items: items,
        getList: getList
    }
}

module.exports = CategoriesService;