function BackButtonDirective ()
{
    return {
        restrict: 'E',
        template: `<a href="#" class="go-back-button" ng-click="dCtrl.goBack()" title="Назад" aria-hidden="true"></a>`,
        bindToController: true,
        controllerAs: 'dCtrl',
        controller: function ( $window )
        {
            "ngInject";

            var vm = this;
            vm.goBack = function ()
            {
                $window.history.back();
            }
        }
    }
}

module.exports = BackButtonDirective;