function ReviewsItemController( CityConfigService ) {
  "ngInject";

  var vm = this;
  vm.cityConfig = CityConfigService;
}

module.exports = {
  templateUrl: '/partials/components/ReviewsItemTemplate.tpl?' + __webpack_hash__,
  controller: ReviewsItemController,
  controllerAs: 'reviewsItemCtrl',
  bindings: {
    review: '<'
  }
}