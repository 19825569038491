function CityConfigService ($rootScope, $http)
{
    "ngInject";

    var options = {
        city: null,
        config: null,
        categories: null
    };

    var init = function ( cityObject )
    {
        options.city = cityObject;
        $http
            .get( cityObject.base_url + "/api/handler.php?request=getConfig" )
            .then( function ( response ) {
                options.config = response.data.data
                $rootScope.$broadcast('after-city-change')
            })
            .catch( angular.noop );
    }

    var getSlug = function () {
        return options.city.slug;
    }

    return {
        getOptions: function () {
            return options;
        },
        options: options,
        getSlug: getSlug,
        init: init
    }
}

module.exports = CityConfigService;